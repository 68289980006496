import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import DefaultLayout from "../../layouts/DefaultLayout";
import addreactionface from "../../images/icon/add_reaction.png";
import { toast } from "react-toastify";
import { providersList } from "../../redux/actions/providerActions";
import {
  addNewCase,
  detailCase,
  updateCase,
} from "../../redux/actions/caseActions";

import GoogleComponent from "react-google-autocomplete";

import Select from "react-select";

import { useDropzone } from "react-dropzone";
import { getInsuranesList } from "../../redux/actions/insuranceActions";
import { getListCoordinators } from "../../redux/actions/userActions";
import { COUNTRIES, CURRENCYITEMS } from "../../constants";
import CurrencyList from "currency-list";

const STEPSLIST = [
  {
    index: 0,
    title: "General Information",
    description:
      "Please enter the general information about the patient and the case.",
  },
  {
    index: 1,
    title: "Coordination Details",
    description:
      "Provide information about the initial coordination & appointment details for this case.",
  },
  {
    index: 2,
    title: "Medical Reports",
    description: "Upload any initial medical reports related to the case.",
  },
  {
    index: 3,
    title: "Invoices",
    description:
      "If there are any initial invoices related to the case, please provide the details and upload the documents.",
  },
  {
    index: 4,
    title: "Insurance Authorization",
    description:
      "Please provide the details of the insurance authorization for this case, and upload any relevant documents.",
  },
  {
    index: 5,
    title: "Finish",
    description: "You can go back to any step to make changes.",
  },
];

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

function EditCaseScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  let { id } = useParams();
  const [searchParams] = useSearchParams();
  const section = searchParams.get("section") || 0;

  //
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [birthDate, setBirthDate] = useState("");
  const [birthDateError, setBirthDateError] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");

  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");

  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState("");
  //
  const [coordinator, setCoordinator] = useState("");
  const [coordinatorError, setCoordinatorError] = useState("");

  const [providerServices, setProviderServices] = useState([]);
  const [providerMultiSelect, setProviderMultiSelect] = useState([]);
  const [providerMultiSelectDelete, setProviderMultiSelectDelete] = useState(
    []
  );
  const [providerMultiSelectLast, setProviderMultiSelectLast] = useState([]);

  const [providerService, setProviderService] = useState("");
  const [providerServiceError, setProviderServiceError] = useState("");

  const [caseDate, setCaseDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [caseDateError, setCaseDateError] = useState("");

  const [caseType, setCaseType] = useState("");
  const [caseTypeError, setCaseTypeError] = useState("");

  const [caseDescription, setCaseDescription] = useState("");
  const [caseDescriptionError, setCaseDescriptionError] = useState("");

  const [isPay, setIsPay] = useState(false);

  const [currencyCode, setCurrencyCode] = useState("");
  const [currencyCodeError, setCurrencyCodeError] = useState("");

  const [priceTotal, setPriceTotal] = useState(0);
  const [priceTotalError, setPriceTotalError] = useState("");
  //
  const [coordinatStatus, setCoordinatStatus] = useState("");
  const [coordinatStatusError, setCoordinatStatusError] = useState("");

  const [coordinatStatusList, setCoordinatStatusList] = useState([]);
  const [coordinatStatusListError, setCoordinatStatusListError] = useState("");

  const [appointmentDate, setAppointmentDate] = useState("");
  const [appointmentDateError, setAppointmentDateError] = useState("");

  const [serviceLocation, setServiceLocation] = useState("");
  const [serviceLocationError, setServiceLocationError] = useState("");
  //
  const [providerName, setProviderName] = useState("");
  const [providerNameError, setProviderNameError] = useState("");

  const [providerPhone, setProviderPhone] = useState("");
  const [providerPhoneError, setProviderPhoneError] = useState("");

  const [providerEmail, setProviderEmail] = useState("");
  const [providerEmailError, setProviderEmailError] = useState("");

  const [providerAddress, setProviderAddress] = useState("");
  const [providerAddressError, setProviderAddressError] = useState("");
  //
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceNumberError, setInvoiceNumberError] = useState("");

  const [dateIssued, setDateIssued] = useState("");
  const [dateIssuedError, setDateIssuedError] = useState("");

  const [amount, setAmount] = useState(0);
  const [amountError, setAmountError] = useState("");
  //
  const [insuranceCompany, setInsuranceCompany] = useState("");
  const [insuranceCompanyError, setInsuranceCompanyError] = useState("");

  const [insuranceNumber, setInsuranceNumber] = useState("");
  const [insuranceNumberError, setInsuranceNumberError] = useState("");

  const [policyNumber, setPolicyNumber] = useState("");
  const [policyNumberError, setPolicyNumberError] = useState("");

  const [initialStatus, setInitialStatus] = useState("");
  const [initialStatusError, setInitialStatusError] = useState("");

  // fiels deleted
  const [fileDeleted, setFileDeleted] = useState([]);
  const [itemsInitialMedicalReports, setItemsInitialMedicalReports] = useState(
    []
  );
  const [itemsUploadInvoice, setItemsUploadInvoice] = useState([]);
  const [
    itemsUploadAuthorizationDocuments,
    setItemsUploadAuthorizationDocuments,
  ] = useState([]);

  // fils
  // initialMedicalReports
  const [filesInitialMedicalReports, setFilesInitialMedicalReports] = useState(
    []
  );
  const {
    getRootProps: getRootPropsInitialMedical,
    getInputProps: getInputPropsInitialMedical,
  } = useDropzone({
    accept: {
      "*": [],
    },
    onDrop: (acceptedFiles) => {
      setFilesInitialMedicalReports((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  useEffect(() => {
    return () =>
      filesInitialMedicalReports.forEach((file) =>
        URL.revokeObjectURL(file.preview)
      );
  }, []);

  // Upload Invoice
  const [filesUploadInvoice, setFilesUploadInvoice] = useState([]);
  const {
    getRootProps: getRootPropsUploadInvoice,
    getInputProps: getInputPropsUploadInvoice,
  } = useDropzone({
    accept: {
      "*": [],
    },
    onDrop: (acceptedFiles) => {
      setFilesUploadInvoice((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  useEffect(() => {
    return () =>
      filesUploadInvoice.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);
  // Upload Authorization Documents
  const [
    filesUploadAuthorizationDocuments,
    setFilesUploadAuthorizationDocuments,
  ] = useState([]);
  const {
    getRootProps: getRootPropsUploadAuthorizationDocuments,
    getInputProps: getInputPropsUploadAuthorizationDocuments,
  } = useDropzone({
    accept: {
      "*": [],
    },
    onDrop: (acceptedFiles) => {
      setFilesUploadAuthorizationDocuments((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  useEffect(() => {
    return () =>
      filesUploadAuthorizationDocuments.forEach((file) =>
        URL.revokeObjectURL(file.preview)
      );
  }, []);

  // Configure react-dropzone

  //

  const [stepSelect, setStepSelect] = useState(parseInt(section) ?? 0);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const listProviders = useSelector((state) => state.providerList);
  const { providers, loadingProviders, errorProviders } = listProviders;

  const listInsurances = useSelector((state) => state.insuranceList);
  const { insurances, loadingInsurances, errorInsurances } = listInsurances;

  const caseDetail = useSelector((state) => state.detailCase);
  const { loadingCaseInfo, errorCaseInfo, successCaseInfo, caseInfo } =
    caseDetail;

  const listCoordinators = useSelector((state) => state.coordinatorsList);
  const { coordinators, loadingCoordinators, errorCoordinators } =
    listCoordinators;

  const caseUpdate = useSelector((state) => state.updateCase);
  const { loadingCaseUpdate, errorCaseUpdate, successCaseUpdate } = caseUpdate;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getListCoordinators("0"));
      dispatch(providersList("0"));
      dispatch(getInsuranesList("0"));
      dispatch(detailCase(id));
      console.log(stepSelect);
    }
  }, [navigate, userInfo, dispatch]);

  useEffect(() => {
    if (successCaseUpdate) {
      setStepSelect(5);
    }
  }, [successCaseUpdate]);

  useEffect(() => {
    if (caseInfo !== undefined && caseInfo !== null) {
      if (caseInfo.patient) {
        setFirstName(caseInfo.patient.first_name ?? "");
        setLastName(caseInfo.patient.last_name ?? "");
        setBirthDate(caseInfo.patient.birth_day ?? "");
        setPhone(caseInfo.patient.patient_phone ?? "");
        setEmail(caseInfo.patient.patient_email ?? "");
        setAddress(caseInfo.patient.patient_address ?? "");

        const patientCountry = caseInfo.patient.patient_country ?? "";
        const foundCountry = COUNTRIES.find(
          (option) => option.title === patientCountry
        );

        if (foundCountry) {
          setCountry({
            value: foundCountry.title,
            label: (
              <div className="flex flex-row items-center">
                <span className="mr-2">{foundCountry.icon}</span>
                <span>{foundCountry.title}</span>
              </div>
            ),
          });
        } else {
          setCountry("");
        }

        setCity(caseInfo.patient.patient_city ?? "");
      }

      const patientCurrency = caseInfo.currency_price ?? "";

      const foundCurrency = CURRENCYITEMS?.find(
        (option) => option.code === patientCurrency
      );

      if (foundCurrency) {
        setCurrencyCode({
          value: foundCurrency.code,
          label:
            foundCurrency.name !== ""
              ? foundCurrency.name + " (" + foundCurrency.code + ") " || ""
              : "",
        });
      } else {
        setCurrencyCode("");
      }

      setIsPay(caseInfo.is_pay);
      setPriceTotal(caseInfo.price_tatal ?? 0);
      // setCoordinator(caseInfo.coordinator ?? "");
      if (caseInfo.coordinator_user) {
        var initialCoordinator = caseInfo.coordinator_user?.id ?? "";
        const foundCoordinator = coordinators?.find(
          (item) => item.id === initialCoordinator
        );

        if (foundCoordinator) {
          setCoordinator({
            value: foundCoordinator.id,
            label: foundCoordinator.full_name,
          });
        } else {
          setCoordinator("");
        }
      }
      setCaseDate(caseInfo.case_date ?? "");
      setCaseType(caseInfo.case_type ?? "");
      setCaseDescription(caseInfo.case_description ?? "");
      //
      const statuses =
        caseInfo?.case_status?.map((status) => status?.status_coordination) ||
        []; // Default to an empty array if case_status is undefined or not an array

      setCoordinatStatusList(statuses);

      //
      setCoordinatStatus(caseInfo.status_coordination ?? "");
      setAppointmentDate(caseInfo.appointment_date ?? "");
      setServiceLocation(caseInfo.service_location ?? "");
      if (caseInfo.provider) {
        var initialProvider = caseInfo.provider?.id ?? "";
        const foundProvider = providers?.find(
          (item) => item.id === initialProvider
        );
        if (foundProvider) {
          setProviderName({
            value: foundProvider.id,
            label: foundProvider.full_name,
          });
        } else {
          setProviderName("");
        }
      }
      if (caseInfo.provider_services) {
        setProviderMultiSelectLast(caseInfo.provider_services ?? []);
      }
      //
      setItemsInitialMedicalReports([]);
      if (caseInfo.medical_reports) {
        setItemsInitialMedicalReports(caseInfo.medical_reports);
      }
      //
      setInvoiceNumber(caseInfo.invoice_number ?? "");
      setDateIssued(caseInfo.date_issued ?? "");
      setAmount(caseInfo.invoice_amount ?? 0);
      setItemsUploadInvoice([]);
      if (caseInfo.upload_invoices) {
        setItemsUploadInvoice(caseInfo.upload_invoices);
      }
      //
      if (caseInfo.assurance) {
        var initialInsurance = caseInfo.assurance?.id ?? "";

        var foundInsurance = insurances?.find(
          (item) => item.id === initialInsurance
        );

        if (foundInsurance) {
          console.log("here 2");
          setInsuranceCompany({
            value: foundInsurance.id,
            label: foundInsurance.assurance_name || "",
          });
        } else {
          console.log("here 3");
          setInsuranceCompany({
            value: "",
            label: "",
          });
        }
      }
      setPolicyNumber(caseInfo.policy_number ?? "");
      setInsuranceNumber(caseInfo.assurance_number ?? "");
      setInitialStatus(caseInfo.assurance_status ?? "");
      setItemsUploadAuthorizationDocuments([]);
      if (caseInfo.upload_authorization) {
        setItemsUploadAuthorizationDocuments(caseInfo.upload_authorization);
      }
      //
    }
  }, [caseInfo]);

  return (
    <DefaultLayout>
      <div className="">
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Edit Case</div>
        </div>
        {/*  */}
        <div className="py-5 px-4 flex justify-between">
          <h4 className=" uppercase font-semibold text-black dark:text-white">
            Edit Case
          </h4>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="flex md:flex-row flex-col">
            <div className="md:w-1/3 w-full flex md:flex-col flex-wrap px-3  relative">
              <div className="w-[1px] h-full absolute top-0 bg-[#0388A6] bg-opacity-60 left-[27.5px] md:block hidden"></div>
              {STEPSLIST?.map((step, index) => (
                <div
                  onClick={() => {
                    if (stepSelect > step.index && stepSelect !== 5) {
                      setStepSelect(step.index);
                    }
                  }}
                  className={`flex flex-row mb-3 md:min-h-20 ${
                    stepSelect > step.index && stepSelect !== 5
                      ? "cursor-pointer"
                      : ""
                  } md:items-start items-center`}
                >
                  {stepSelect < step.index ? (
                    <div className="size-8 bg-[#E2EFF2] z-10  rounded-full text-center items-center flex justify-center text-[#0388A6]">
                      <img
                        src={addreactionface}
                        className="size-5"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "/assets/placeholder.png";
                        }}
                      />
                    </div>
                  ) : stepSelect === step.index ? (
                    <div className="size-8 bg-white z-10  border-[11px] rounded-full"></div>
                  ) : (
                    <div className="size-8 bg-[#E2EFF2] z-10  rounded-full text-center items-center flex justify-center text-[#0388A6]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m4.5 12.75 6 6 9-13.5"
                        />
                      </svg>
                    </div>
                  )}

                  <div className="text-black flex-1 px-2">
                    <div className="font-medium text-sm">{step.title}</div>
                    {stepSelect === step.index ? (
                      <div className="text-xs font-light md:block hidden">
                        {step.description}
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
            <div className="md:w-2/3 w-full  px-3 py-4  bg-[#F8F9FF]">
              {/* step 1 - General Information */}
              {stepSelect === 0 ? (
                <div className="">
                  <div className="text-[#0388A6] font-semibold text-xl">
                    General Information
                  </div>
                  {/* Patient Details: */}
                  <div className="text-xs font-medium mt-5 mb-2 text-black">
                    Patient Details:
                  </div>
                  <div className="my-2 bg-white py-4 px-2 rounded-md">
                    <div className="flex md:flex-row flex-col  ">
                      <div className="md:w-1/2 w-full  md:pr-1 my-1">
                        <div className="text-[#B4B4B4] text-xs  mb-1">
                          First Name <strong className="text-danger">*</strong>
                        </div>
                        <div>
                          <input
                            className={` outline-none border ${
                              firstNameError
                                ? "border-danger"
                                : "border-[#F1F3FF]"
                            } px-3 py-2 w-full rounded text-sm`}
                            type="text"
                            placeholder="First Name"
                            value={firstName}
                            onChange={(v) => setFirstName(v.target.value)}
                          />
                          <div className=" text-[8px] text-danger">
                            {firstNameError ? firstNameError : ""}
                          </div>
                        </div>
                      </div>
                      {/*  */}
                      <div className="md:w-1/2 w-full  md:pl-1 my-1">
                        <div className="text-[#B4B4B4] text-xs mb-1">
                          Last Name
                        </div>
                        <div>
                          <input
                            className=" outline-none border border-[#F1F3FF] px-3 py-2 w-full rounded text-sm"
                            type="text"
                            placeholder="Last Name"
                            value={lastName}
                            onChange={(v) => setLastName(v.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    {/*  */}
                    <div className="flex md:flex-row flex-col ">
                      <div className="md:w-1/2 w-full md:pr-1 my-1">
                        <div className="text-[#B4B4B4] text-xs  mb-1">
                          Email
                        </div>
                        <div>
                          <input
                            className={` outline-none border ${
                              emailError ? "border-danger" : "border-[#F1F3FF]"
                            } px-3 py-2 w-full rounded text-sm`}
                            type="email"
                            placeholder="Email Address"
                            value={email}
                            onChange={(v) => setEmail(v.target.value)}
                          />
                          <div className=" text-[8px] text-danger">
                            {emailError ? emailError : ""}
                          </div>
                        </div>
                      </div>
                      {/*  */}
                      <div className="md:w-1/2 w-full  md:pl-1 my-1">
                        <div className="text-[#B4B4B4] text-xs mb-1">
                          phone <strong className="text-danger">*</strong>
                        </div>
                        <div>
                          <input
                            className={`outline-none border ${
                              phoneError ? "border-danger" : "border-[#F1F3FF]"
                            } px-3 py-2 w-full rounded text-sm`}
                            type="text"
                            placeholder="Phone no"
                            value={phone}
                            onChange={(v) => setPhone(v.target.value)}
                          />
                          <div className=" text-[8px] text-danger">
                            {phoneError ? phoneError : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*  */}
                    <div className="flex md:flex-row flex-col ">
                      <div className="w-full md:pr-1 my-1">
                        <div className="text-[#B4B4B4] text-xs  mb-1">
                          Country <strong className="text-danger">*</strong>
                        </div>
                        <div>
                          <Select
                            value={country}
                            onChange={(option) => {
                              setCountry(option);
                            }}
                            className="text-sm"
                            options={COUNTRIES.map((country) => ({
                              value: country.title,
                              label: (
                                <div
                                  className={`${
                                    country.title === "" ? "py-2" : ""
                                  } flex flex-row items-center`}
                                >
                                  <span className="mr-2">{country.icon}</span>
                                  <span>{country.title}</span>
                                </div>
                              ),
                            }))}
                            placeholder="Select a country..."
                            isSearchable
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                background: "#fff",
                                border: countryError
                                  ? "1px solid #d34053"
                                  : "1px solid #F1F3FF",
                                boxShadow: state.isFocused ? "none" : "none",
                                "&:hover": {
                                  border: "1px solid #F1F3FF",
                                },
                              }),
                              option: (base) => ({
                                ...base,
                                display: "flex",
                                alignItems: "center",
                              }),
                              singleValue: (base) => ({
                                ...base,
                                display: "flex",
                                alignItems: "center",
                              }),
                            }}
                          />
                          <div className=" text-[8px] text-danger">
                            {countryError ? countryError : ""}
                          </div>
                        </div>
                      </div>
                      <div className="w-full md:pr-1 my-1">
                        <div className="text-[#B4B4B4] text-xs  mb-1">
                          City <strong className="text-danger">*</strong>
                        </div>
                        <div>
                          <GoogleComponent
                            apiKey="AIzaSyCozE2Q3aj449xsY28qeQ4-C5_IBOg21Ng"
                            className={` outline-none border ${
                              cityError ? "border-danger" : "border-[#F1F3FF]"
                            } px-3 py-2 w-full rounded text-sm`}
                            onChange={(v) => {
                              setCity(v.target.value);
                            }}
                            onPlaceSelected={(place) => {
                              if (place && place.geometry) {
                                setCity(place.formatted_address ?? "");
                                // setCityVl(place.formatted_address ?? "");
                                //   const latitude = place.geometry.location.lat();
                                //   const longitude = place.geometry.location.lng();
                                //   setLocationX(latitude ?? "");
                                //   setLocationY(longitude ?? "");
                              }
                            }}
                            defaultValue={city}
                            types={["city"]}
                            language="en"
                          />
                          {/* <input
                            className={` outline-none border ${
                              cityError ? "border-danger" : "border-[#F1F3FF]"
                            }  px-3 py-2 w-full rounded text-sm`}
                            type="text"
                            placeholder="City"
                            value={city}
                            onChange={(v) => setCity(v.target.value)}
                          /> */}
                          <div className=" text-[8px] text-danger">
                            {cityError ? cityError : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*  */}
                    <div className="flex md:flex-row flex-col ">
                      <div className="md:w-1/2 w-full  md:pr-1 my-1">
                        <div className="text-[#B4B4B4] text-xs  mb-1">CIA</div>
                        <div>
                          <Select
                            value={insuranceCompany}
                            onChange={(option) => {
                              setInsuranceCompany(option);
                            }}
                            options={insurances?.map((assurance) => ({
                              value: assurance.id,
                              label: assurance.assurance_name || "",
                            }))}
                            filterOption={(option, inputValue) =>
                              option.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                            }
                            className="text-sm"
                            placeholder="Select Insurance..."
                            isSearchable
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                background: "#fff",
                                border: insuranceCompanyError
                                  ? "1px solid #d34053"
                                  : "1px solid #F1F3FF",
                                boxShadow: state.isFocused ? "none" : "none",
                                "&:hover": {
                                  border: "1px solid #F1F3FF",
                                },
                              }),
                              option: (base) => ({
                                ...base,
                                display: "flex",
                                alignItems: "center",
                              }),
                              singleValue: (base) => ({
                                ...base,
                                display: "flex",
                                alignItems: "center",
                              }),
                            }}
                          />
                          <div className=" text-[8px] text-danger">
                            {insuranceCompanyError ? insuranceCompanyError : ""}
                          </div>
                        </div>
                      </div>
                      <div className="md:w-1/2 w-full  md:pr-1 my-1">
                        <div className="text-[#B4B4B4] text-xs  mb-1">
                          CIA Reference
                        </div>
                        <div>
                          <input
                            className={` outline-none border ${
                              insuranceNumberError
                                ? "border-danger"
                                : "border-[#F1F3FF]"
                            }  px-3 py-2 w-full rounded text-sm`}
                            type="text"
                            placeholder="CIA Reference"
                            value={insuranceNumber}
                            onChange={(v) => setInsuranceNumber(v.target.value)}
                          />
                          <div className=" text-[8px] text-danger">
                            {insuranceNumberError ? insuranceNumberError : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Case Details: */}
                  <div className="text-xs font-medium mt-5 mb-2 text-black">
                    Case Details:
                  </div>
                  <div className="my-2 bg-white py-4 px-2 rounded-md">
                    <div className="flex md:flex-row flex-col  ">
                      <div className=" w-full  md:pr-1 my-1">
                        <div className="text-[#B4B4B4] text-xs  mb-1">
                          Assigned Coordinator{" "}
                          <strong className="text-danger">*</strong>
                        </div>
                        <div>
                          <Select
                            value={coordinator}
                            onChange={(option) => {
                              setCoordinator(option);
                            }}
                            className="text-sm"
                            options={coordinators?.map((item) => ({
                              value: item.id,
                              label: item.full_name || "",
                            }))}
                            filterOption={(option, inputValue) =>
                              option.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                            }
                            placeholder="Select Coordinator..."
                            isSearchable
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                background: "#fff",
                                border: coordinatorError
                                  ? "1px solid #d34053"
                                  : "1px solid #F1F3FF",
                                boxShadow: state.isFocused ? "none" : "none",
                                "&:hover": {
                                  border: "1px solid #F1F3FF",
                                },
                              }),
                              option: (base) => ({
                                ...base,
                                display: "flex",
                                alignItems: "center",
                              }),
                              singleValue: (base) => ({
                                ...base,
                                display: "flex",
                                alignItems: "center",
                              }),
                            }}
                          />
                          <div className=" text-[8px] text-danger">
                            {coordinatorError ? coordinatorError : ""}
                          </div>
                        </div>
                      </div>
                      {/*  */}
                    </div>
                    {/*  */}
                    <div className="flex md:flex-row flex-col  ">
                      <div className="md:w-1/2 w-full  md:pl-1 my-1">
                        <div className="text-[#B4B4B4] text-xs mb-1">
                          Case Creation Date{" "}
                          <strong className="text-danger">*</strong>
                        </div>
                        <div>
                          <input
                            className={` outline-none border ${
                              caseDateError
                                ? "border-danger"
                                : "border-[#F1F3FF]"
                            } px-3 py-2 w-full rounded text-sm`}
                            type="date"
                            placeholder="Case Creation Date"
                            value={caseDate}
                            onChange={(v) => setCaseDate(v.target.value)}
                          />
                          <div className=" text-[8px] text-danger">
                            {caseDateError ? caseDateError : ""}
                          </div>
                        </div>
                      </div>
                      <div className="md:w-1/2  w-full  md:pr-1 my-1">
                        <div className="text-[#B4B4B4] text-xs  mb-1">
                          Type <strong className="text-danger">*</strong>
                        </div>
                        <div>
                          <select
                            value={caseType}
                            onChange={(v) => setCaseType(v.target.value)}
                            className={` outline-none border ${
                              caseTypeError
                                ? "border-danger"
                                : "border-[#F1F3FF]"
                            } px-3 py-2 w-full rounded text-sm`}
                          >
                            <option value={""}>Select Type</option>
                            <option value={"Medical"}>Medical</option>
                            <option value={"Technical"}>Technical</option>
                          </select>
                          <div className=" text-[8px] text-danger">
                            {caseTypeError ? caseTypeError : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*  */}
                    <div className="flex md:flex-row flex-col ">
                      <div className="md:w-1/2 w-full  md:pr-1 my-1">
                        <div className="text-[#B4B4B4] text-xs  mb-1">
                          Currency Code{" "}
                          <strong className="text-danger">*</strong>
                        </div>
                        <div>
                          <Select
                            value={currencyCode}
                            onChange={(option) => {
                              setCurrencyCode(option);
                            }}
                            options={CURRENCYITEMS?.map((currency) => ({
                              value: currency.code,
                              label:
                                currency.name !== ""
                                  ? currency.name +
                                      " (" +
                                      currency.code +
                                      ") " || ""
                                  : "",
                            }))}
                            filterOption={(option, inputValue) =>
                              option.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                            }
                            className="text-sm"
                            placeholder="Select Currency Code ..."
                            isSearchable
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                background: "#fff",
                                border: currencyCodeError
                                  ? "1px solid #d34053"
                                  : "1px solid #F1F3FF",
                                boxShadow: state.isFocused ? "none" : "none",
                                "&:hover": {
                                  border: "1px solid #F1F3FF",
                                },
                              }),
                              option: (base) => ({
                                ...base,
                                display: "flex",
                                alignItems: "center",
                              }),
                              singleValue: (base) => ({
                                ...base,
                                display: "flex",
                                alignItems: "center",
                              }),
                            }}
                          />
                          <div className=" text-[8px] text-danger">
                            {currencyCodeError ? currencyCodeError : ""}
                          </div>
                        </div>
                      </div>
                      <div className="md:w-1/2 w-full  md:pr-1 my-1">
                        <div className="text-[#B4B4B4] text-xs  mb-1">
                          Price of service{" "}
                          <strong className="text-danger">*</strong>
                        </div>
                        <div>
                          <input
                            className={` outline-none border ${
                              priceTotalError
                                ? "border-danger"
                                : "border-[#F1F3FF]"
                            }  px-3 py-2 w-full rounded text-sm`}
                            type="number"
                            min={0}
                            step={0.01}
                            placeholder="0.00"
                            value={priceTotal}
                            onChange={(v) => setPriceTotal(v.target.value)}
                          />
                          <div className=" text-[8px] text-danger">
                            {priceTotalError ? priceTotalError : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex md:flex-row flex-col ">
                      <div className="md:w-1/2 w-full  md:pr-1 my-1">
                        <div>
                          <input
                            type={"checkbox"}
                            name="ispay"
                            id="ispay"
                            checked={isPay === true}
                            onChange={(v) => {
                              setIsPay(true);
                            }}
                          />
                          <label
                            className="mx-1 text-[#B4B4B4] text-sm  cursor-pointer"
                            for="ispay"
                          >
                            Paid
                          </label>
                        </div>
                      </div>
                      <div className="md:w-1/2 w-full  md:pr-1 my-1">
                        <div>
                          <input
                            type={"checkbox"}
                            name="notpay"
                            id="notpay"
                            checked={isPay === false}
                            onChange={(v) => {
                              setIsPay(false);
                            }}
                          />
                          <label
                            className="mx-1 text-[#B4B4B4] text-sm  cursor-pointer"
                            for="notpay"
                          >
                            Unpaid
                          </label>
                        </div>
                      </div>
                    </div>

                    {/*  */}
                    <div className="flex md:flex-row flex-col  ">
                      <div className=" w-full  md:pr-1 my-1">
                        <div className="text-[#B4B4B4] text-xs  mb-1">
                          Description
                        </div>
                        <div>
                          <textarea
                            value={caseDescription}
                            rows={5}
                            onChange={(v) => setCaseDescription(v.target.value)}
                            className=" outline-none border border-[#F1F3FF] px-3 py-2 w-full rounded text-sm"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Save & Continue - step 1 */}
                  <div className="flex flex-row items-center justify-end my-3">
                    <button
                      onClick={() => {
                        var check = true;
                        setFirstNameError("");
                        setLastNameError("");
                        setBirthDateError("");
                        setPhoneError("");
                        setEmailError("");
                        setAddressError("");
                        setCaseTypeError("");
                        setCaseDateError("");
                        setCoordinatorError("");
                        setCityError("");
                        setCountryError("");
                        setCurrencyCodeError("");
                        setPriceTotalError("");

                        if (firstName === "") {
                          setFirstNameError("This field is required.");
                          check = false;
                        }

                        if (phone === "") {
                          setPhoneError("This field is required.");
                          check = false;
                        }

                        if (country === "" || country.value === "") {
                          setCountryError("This field is required.");
                          check = false;
                        }

                        if (coordinator === "" || coordinator.value === "") {
                          setCoordinatorError("This field is required.");
                          check = false;
                        }

                        if (caseType === "") {
                          setCaseTypeError("This field is required.");
                          check = false;
                        }
                        if (caseDate === "") {
                          setCaseDateError("This field is required.");
                          check = false;
                        }
                        if (currencyCode === "" || currencyCode.value === "") {
                          setCurrencyCodeError("This field is required.");
                          check = false;
                        }
                        if (priceTotal === "") {
                          setPriceTotalError("This field is required.");
                          check = false;
                        }
                        if (check) {
                          setStepSelect(1);
                        } else {
                          toast.error(
                            "Some fields are empty or invalid. please try again"
                          );
                        }
                      }}
                      className="text-white bg-[#0388A6] text-sm px-5 py-3 rounded-full"
                    >
                      Save & Continue
                    </button>
                  </div>
                </div>
              ) : null}
              {/* step 2 */}
              {stepSelect === 1 ? (
                <div className="">
                  <div className="text-[#0388A6] font-semibold text-xl">
                    Coordination Details
                  </div>
                  {/* Initial Coordination Status: */}
                  <div className="text-xs font-medium mt-5 mb-2 text-black">
                    Initial Coordination Status:
                  </div>
                  <div className="my-2 bg-white py-4 px-2 rounded-md">
                    <div className="flex md:flex-row flex-col  ">
                      <div className="w-full  md:pr-1 my-1">
                        <div className="text-[#B4B4B4] text-xs  mb-1">
                          Status <strong className="text-danger">*</strong>
                        </div>
                        <div>
                          <div className="flex flex-wrap">
                            <div className="flex flex-row text-xs items-center my-3 text-danger">
                              <input
                                onChange={(v) => {
                                  if (
                                    !coordinatStatusList.includes(
                                      "pending-coordination"
                                    )
                                  ) {
                                    setCoordinatStatusList([
                                      ...coordinatStatusList,
                                      "pending-coordination",
                                    ]);
                                  } else {
                                    setCoordinatStatusList(
                                      coordinatStatusList.filter(
                                        (status) =>
                                          status !== "pending-coordination"
                                      )
                                    );
                                  }
                                }}
                                id="pending-coordination"
                                type={"checkbox"}
                                checked={coordinatStatusList.includes(
                                  "pending-coordination"
                                )}
                                className="mx-1"
                              />
                              <label
                                for="pending-coordination"
                                className="flex-1 mx-1  cursor-pointer "
                              >
                                Pending Coordination
                              </label>
                            </div>
                            <div className="flex flex-row text-xs items-center my-3 text-[#FFA500]">
                              <input
                                onChange={(v) => {
                                  if (
                                    !coordinatStatusList.includes(
                                      "coordinated-missing-m-r"
                                    )
                                  ) {
                                    setCoordinatStatusList([
                                      ...coordinatStatusList,
                                      "coordinated-missing-m-r",
                                    ]);
                                  } else {
                                    setCoordinatStatusList(
                                      coordinatStatusList.filter(
                                        (status) =>
                                          status !== "coordinated-missing-m-r"
                                      )
                                    );
                                  }
                                }}
                                checked={coordinatStatusList.includes(
                                  "coordinated-missing-m-r"
                                )}
                                id="coordinated-Missing-m-r"
                                type={"checkbox"}
                                className="mx-1"
                              />
                              <label
                                for="coordinated-Missing-m-r"
                                className="flex-1 mx-1  cursor-pointer "
                              >
                                Coordinated, Missing M.R.
                              </label>
                            </div>
                            <div className="flex flex-row text-xs items-center my-3 text-[#FFA500]">
                              <input
                                onChange={(v) => {
                                  if (
                                    !coordinatStatusList.includes(
                                      "coordinated-missing-invoice"
                                    )
                                  ) {
                                    setCoordinatStatusList([
                                      ...coordinatStatusList,
                                      "coordinated-missing-invoice",
                                    ]);
                                  } else {
                                    setCoordinatStatusList(
                                      coordinatStatusList.filter(
                                        (status) =>
                                          status !==
                                          "coordinated-missing-invoice"
                                      )
                                    );
                                  }
                                }}
                                checked={coordinatStatusList.includes(
                                  "coordinated-missing-invoice"
                                )}
                                id="coordinated-missing-invoice"
                                type={"checkbox"}
                                className="mx-1"
                              />
                              <label
                                for="coordinated-missing-invoice"
                                className="flex-1 mx-1  cursor-pointer "
                              >
                                Coordinated, Missing Invoice
                              </label>
                            </div>
                            <div className="flex flex-row text-xs items-center my-3 text-primary">
                              <input
                                onChange={(v) => {
                                  if (
                                    !coordinatStatusList.includes(
                                      "waiting-for-insurance-authorization"
                                    )
                                  ) {
                                    setCoordinatStatusList([
                                      ...coordinatStatusList,
                                      "waiting-for-insurance-authorization",
                                    ]);
                                  } else {
                                    setCoordinatStatusList(
                                      coordinatStatusList.filter(
                                        (status) =>
                                          status !==
                                          "waiting-for-insurance-authorization"
                                      )
                                    );
                                  }
                                }}
                                checked={coordinatStatusList.includes(
                                  "waiting-for-insurance-authorization"
                                )}
                                id="waiting-for-insurance-authorization"
                                type={"checkbox"}
                                className="mx-1"
                              />
                              <label
                                for="waiting-for-insurance-authorization"
                                className="flex-1 mx-1  cursor-pointer "
                              >
                                Waiting for Insurance Authorization
                              </label>
                            </div>
                            <div className="flex flex-row text-xs items-center my-3 text-primary">
                              <input
                                onChange={(v) => {
                                  if (
                                    !coordinatStatusList.includes(
                                      "coordinated-patient-not-seen-yet"
                                    )
                                  ) {
                                    setCoordinatStatusList([
                                      ...coordinatStatusList,
                                      "coordinated-patient-not-seen-yet",
                                    ]);
                                  } else {
                                    setCoordinatStatusList(
                                      coordinatStatusList.filter(
                                        (status) =>
                                          status !==
                                          "coordinated-patient-not-seen-yet"
                                      )
                                    );
                                  }
                                }}
                                checked={coordinatStatusList.includes(
                                  "coordinated-patient-not-seen-yet"
                                )}
                                id="coordinated-patient-not-seen-yet"
                                type={"checkbox"}
                                className="mx-1"
                              />
                              <label
                                for="coordinated-patient-not-seen-yet"
                                className="flex-1 mx-1  cursor-pointer "
                              >
                                Coordinated, Patient not seen yet
                              </label>
                            </div>
                            <div className="flex flex-row text-xs items-center my-3 text-[#008000]">
                              <input
                                onChange={(v) => {
                                  if (
                                    !coordinatStatusList.includes(
                                      "fully-coordinated"
                                    )
                                  ) {
                                    setCoordinatStatusList([
                                      ...coordinatStatusList,
                                      "fully-coordinated",
                                    ]);
                                  } else {
                                    setCoordinatStatusList(
                                      coordinatStatusList.filter(
                                        (status) =>
                                          status !== "fully-coordinated"
                                      )
                                    );
                                  }
                                }}
                                checked={coordinatStatusList.includes(
                                  "fully-coordinated"
                                )}
                                id="fully-coordinated"
                                type={"checkbox"}
                                className="mx-1"
                              />
                              <label
                                for="fully-coordinated"
                                className="flex-1 mx-1  cursor-pointer "
                              >
                                Fully Coordinated
                              </label>
                            </div>
                            <div className="flex flex-row text-xs items-center my-3 text-[#d34053]">
                              <input
                                onChange={(v) => {
                                  if (!coordinatStatusList.includes("failed")) {
                                    setCoordinatStatusList([
                                      ...coordinatStatusList,
                                      "failed",
                                    ]);
                                  } else {
                                    setCoordinatStatusList(
                                      coordinatStatusList.filter(
                                        (status) => status !== "failed"
                                      )
                                    );
                                  }
                                }}
                                checked={coordinatStatusList.includes("failed")}
                                id="failed"
                                type={"checkbox"}
                                className="mx-1"
                              />
                              <label
                                for="failed"
                                className="flex-1 mx-1  cursor-pointer "
                              >
                                Failed
                              </label>
                            </div>
                          </div>
                          {/* <select
                            value={coordinatStatus}
                            onChange={(v) => setCoordinatStatus(v.target.value)}
                            className={`outline-none border ${
                              coordinatStatusError
                                ? "border-danger"
                                : "border-[#F1F3FF]"
                            }  px-3 py-2 w-full rounded text-sm`}
                          >
                            <option value={""}>Select Status</option>
                            <option value={"pending-coordination"}>
                              Pending Coordination
                            </option>
                            <option value={"coordinated-missing-m-r"}>
                              Coordinated, Missing M.R.
                            </option>
                            <option value={"coordinated-missing-invoice"}>
                              Coordinated, Missing Invoice
                            </option>
                            <option
                              value={"waiting-for-insurance-authorization"}
                            >
                              Waiting for Insurance Authorization
                            </option>
                            <option value={"coordinated-patient-not-seen-yet"}>
                              Coordinated, Patient not seen yet
                            </option>
                            <option value={"fully-coordinated"}>
                              Fully Coordinated
                            </option>
                            <option value={"failed"}>Failed</option>
                          </select> */}
                          <div className=" text-[8px] text-danger">
                            {coordinatStatusListError
                              ? coordinatStatusListError
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Appointment Details: */}
                  <div className="text-xs font-medium mt-5 mb-2 text-black">
                    Appointment Details:
                  </div>
                  <div className="my-2 bg-white py-4 px-2 rounded-md">
                    <div className="flex md:flex-row flex-col  ">
                      <div className="md:w-1/2 w-full  md:pr-1 my-1">
                        <div className="text-[#B4B4B4] text-xs  mb-1">
                          Appointment Date
                        </div>
                        <div>
                          <input
                            className=" outline-none border border-[#F1F3FF] px-3 py-2 w-full rounded text-sm"
                            type="date"
                            placeholder="Appointment Date"
                            value={appointmentDate}
                            onChange={(v) => setAppointmentDate(v.target.value)}
                          />
                        </div>
                      </div>
                      {/*  */}
                      <div className="md:w-1/2 w-full  md:pr-1 my-1">
                        <div className="text-[#B4B4B4] text-xs  mb-1">
                          Service Location
                        </div>
                        <div>
                          <input
                            className=" outline-none border border-[#F1F3FF] px-3 py-2 w-full rounded text-sm"
                            type="text"
                            placeholder=" Service Location"
                            value={serviceLocation}
                            onChange={(v) => setServiceLocation(v.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Provider Information: */}
                  <div className="text-xs font-medium mt-5 mb-2 text-black">
                    Provider Information:
                  </div>
                  <div className="my-2 bg-white py-4 px-2 rounded-md">
                    <div className="flex md:flex-row flex-col  ">
                      <div className="md:w-1/2  w-full  md:pr-1 my-1">
                        <div className="text-[#B4B4B4] text-xs  mb-1">
                          Provider Name
                        </div>
                        <div>
                          <Select
                            value={providerName}
                            onChange={(option) => {
                              setProviderName(option);
                              //
                              var initialProvider = option?.value ?? "";
                              const foundProvider = providers?.find(
                                (item) => item.id === initialProvider
                              );
                              if (foundProvider) {
                                setProviderServices(
                                  foundProvider.services ?? []
                                );
                              } else {
                                setProviderServices([]);
                              }
                            }}
                            className="text-sm"
                            options={providers?.map((item) => ({
                              value: item.id,
                              label: item.full_name || "",
                            }))}
                            filterOption={(option, inputValue) =>
                              option.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                            }
                            placeholder="Select Provider..."
                            isSearchable
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                background: "#fff",
                                border: providerNameError
                                  ? "1px solid #d34053"
                                  : "1px solid #F1F3FF",
                                boxShadow: state.isFocused ? "none" : "none",
                                "&:hover": {
                                  border: "1px solid #F1F3FF",
                                },
                              }),
                              option: (base) => ({
                                ...base,
                                display: "flex",
                                alignItems: "center",
                              }),
                              singleValue: (base) => ({
                                ...base,
                                display: "flex",
                                alignItems: "center",
                              }),
                            }}
                          />
                          <div className=" text-[8px] text-danger">
                            {providerNameError ? providerNameError : ""}
                          </div>
                        </div>
                      </div>
                      {/*  */}
                      <div className="md:w-1/2  w-full  md:pr-1 my-1">
                        <div className="text-[#B4B4B4] text-xs  mb-1">
                          Provider Service
                        </div>
                        <div>
                          <select
                            className={`outline-none border ${
                              providerServiceError
                                ? "border-danger"
                                : "border-[#F1F3FF]"
                            }  px-3 py-2 w-full rounded text-sm`}
                            onChange={(v) => {
                              setProviderService(v.target.value);
                            }}
                            value={providerService}
                          >
                            <option value={""}></option>
                            {providerServices?.map((service, index) => (
                              <option value={service.id}>
                                {service.service_type ?? ""}
                                {service.service_specialist !== ""
                                  ? " : " + service.service_specialist
                                  : ""}
                              </option>
                            ))}
                          </select>
                          <div className=" text-[8px] text-danger">
                            {providerServiceError ? providerServiceError : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* add  */}
                    <div className="flex flex-col  ">
                      <button
                        onClick={() => {
                          // providerMultiSelect
                          var check = true;
                          setProviderNameError("");
                          setProviderServiceError("");
                          if (
                            providerName === "" ||
                            providerName.value === ""
                          ) {
                            setProviderNameError("These fields are required.");
                            toast.error(" Provider is required");
                            check = false;
                          }
                          if (providerService === "") {
                            setProviderServiceError(
                              "These fields are required."
                            );
                            toast.error(" Provider Service is required");
                            check = false;
                          }

                          if (check) {
                            const exists = providerMultiSelect.some(
                              (provider) =>
                                String(provider?.provider?.id) ===
                                  String(providerName.value) &&
                                String(provider?.service?.id) ===
                                  String(providerService)
                            );

                            const existsLast = providerMultiSelectLast.some(
                              (provider) =>
                                String(provider?.provider?.id) ===
                                  String(providerName.value) &&
                                String(provider?.provider_service?.id) ===
                                  String(providerService)
                            );

                            if (!exists && !existsLast) {
                              // find provider
                              var initialProvider = providerName.value ?? "";
                              const foundProvider = providers?.find(
                                (item) =>
                                  String(item.id) === String(initialProvider)
                              );
                              console.log(foundProvider);

                              if (foundProvider) {
                                // found service
                                var initialService = providerService ?? "";

                                foundProvider?.services?.forEach((element) => {
                                  console.log(element.id);
                                });

                                const foundService =
                                  foundProvider?.services?.find(
                                    (item) =>
                                      String(item.id) === String(initialService)
                                  );

                                if (foundService) {
                                  // Add the new item if it doesn't exist
                                  setProviderMultiSelect([
                                    ...providerMultiSelect,
                                    {
                                      provider: foundProvider,
                                      service: foundService,
                                    },
                                  ]);
                                  setProviderName("");
                                  setProviderService("");
                                  console.log(providerMultiSelect);
                                } else {
                                  setProviderNameError(
                                    "This provider service not exist!"
                                  );
                                  toast.error(
                                    "This provider service not exist!"
                                  );
                                }
                              } else {
                                setProviderNameError(
                                  "This provider not exist!"
                                );
                                toast.error("This provider not exist!");
                              }
                            } else {
                              setProviderNameError(
                                "This provider or service is already added!"
                              );
                              toast.error(
                                "This provider or service is already added!"
                              );
                            }
                          }
                        }}
                        className="text-primary  flex flex-row items-center my-2 text-sm"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                        <span> Add Provider </span>
                      </button>
                      <div className=" w-full  md:pr-1 my-1">
                        <div className="text-[#000000bf] font-bold text-xs  mb-1">
                          Providers
                        </div>
                        <div className="my-2 text-black text-sm">
                          {providerMultiSelectLast?.map(
                            (itemProvider, index) => (
                              <div
                                key={index}
                                className="flex flex-row items-center my-1"
                              >
                                <div className="min-w-6 text-center">
                                  <button
                                    onClick={() => {
                                      const updatedServices =
                                        providerMultiSelectLast.filter(
                                          (_, indexF) => indexF !== index
                                        );
                                      setProviderMultiSelectDelete([
                                        ...providerMultiSelectDelete,
                                        itemProvider.id,
                                      ]);
                                      setProviderMultiSelectLast(
                                        updatedServices
                                      );
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      class="size-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                      />
                                    </svg>
                                  </button>
                                </div>
                                <div className="flex-1 mx-1 border-l px-1">
                                  <div>
                                    <b>Provider:</b>{" "}
                                    {itemProvider.provider?.full_name ?? "---"}
                                  </div>
                                  <div>
                                    <b>Service:</b>{" "}
                                    {itemProvider.provider_service
                                      ?.service_type ?? "--"}
                                  </div>
                                  <div>
                                    <b>Speciality:</b>{" "}
                                    {itemProvider.provider_service
                                      ?.service_specialist ?? "---"}
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                          {providerMultiSelect?.map((itemProvider, index) => (
                            <div
                              key={index}
                              className="flex flex-row items-center my-1"
                            >
                              <div className="min-w-6 text-center">
                                <button
                                  onClick={() => {
                                    const updatedServices =
                                      providerMultiSelect.filter(
                                        (_, indexF) => indexF !== index
                                      );
                                    setProviderMultiSelect(updatedServices);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="size-6"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                    />
                                  </svg>
                                </button>
                              </div>
                              <div className="flex-1 mx-1 border-l px-1">
                                <div>
                                  <b>Provider:</b>{" "}
                                  {itemProvider.provider?.full_name ?? "---"}
                                </div>
                                <div>
                                  <b>Service:</b>{" "}
                                  {itemProvider.service?.service_type ?? "--"}
                                </div>
                                <div>
                                  <b>Speciality:</b>{" "}
                                  {itemProvider.service?.service_specialist ??
                                    "---"}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Save & Continue - step 2 */}
                  <div className="flex flex-row items-center justify-end my-3">
                    <button
                      onClick={() => setStepSelect(0)}
                      className="bg-white text-[#0388A6] text-sm px-5 py-3 rounded-full mr-3"
                    >
                      Back
                    </button>
                    <button
                      onClick={() => {
                        var check = true;
                        setCoordinatStatusError("");
                        setCoordinatStatusListError("");

                        if (coordinatStatusList.length === 0) {
                          setCoordinatStatusListError(
                            "This fields is required."
                          );
                          check = false;
                        }

                        if (check) {
                          setStepSelect(2);
                        } else {
                          toast.error(
                            "Some fields are empty or invalid. please try again"
                          );
                        }
                      }}
                      className="text-white bg-[#0388A6] text-sm px-5 py-3 rounded-full"
                    >
                      Save & Continue
                    </button>
                  </div>
                </div>
              ) : null}
              {/* step 3 */}
              {stepSelect === 2 ? (
                <div className="">
                  <div className="text-[#0388A6] font-semibold text-xl">
                    Medical Reports
                  </div>
                  {/* Initial Medical Reports: */}
                  <div className="text-xs font-medium mt-5 mb-2 text-black">
                    Initial Medical Reports:
                  </div>
                  <div className="my-2 bg-white py-4 px-2 rounded-md">
                    <div
                      {...getRootPropsInitialMedical({ className: "dropzone" })}
                      // style={dropzoneStyle}
                      className="bg-[#F5F6FF] w-full min-h-50 flex flex-col items-center justify-center"
                    >
                      <input {...getInputPropsInitialMedical()} />
                      <div className="my-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="size-8 p-2 bg-[#0388A6] rounded-full text-white"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                          />
                        </svg>
                      </div>
                      <div className="my-2">
                        Drag & Drop Image File or BROWSE
                      </div>
                    </div>
                    <aside style={thumbsContainer}>
                      <div className="w-full flex flex-col ">
                        {itemsInitialMedicalReports
                          ?.filter((file) => !fileDeleted.includes(file.id))
                          .map((file, index) => (
                            <div
                              className="bg-[#F3F5F7] rounded-xl px-5 py-5 w-full my-2 flex flex-row items-center"
                              key={file.file_name}
                            >
                              <div className=" rounded-full  bg-white text-[#81838E] text-center p-2 shadow-1 ">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  class="size-4"
                                >
                                  <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5H5.625Z" />
                                  <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                                </svg>
                              </div>
                              <div className="flex-1 px-5 text-[#303030] text-sm">
                                <div className="whitespace-nowrap overflow-hidden text-ellipsis max-w-full flex-shrink text-xs">
                                  {file.file_name}
                                </div>
                                <div>
                                  {parseFloat(file.file_size).toFixed(2)} mb
                                </div>
                              </div>
                              <button
                                onClick={() => {
                                  setFileDeleted([...fileDeleted, file.id]);
                                }}
                                className="rounded-full p-1 bg-[#E6E7E8] font-bold text-[#9D9D9D]"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="size-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M6 18 18 6M6 6l12 12"
                                  />
                                </svg>
                              </button>
                            </div>
                          ))}
                        {filesInitialMedicalReports?.map((file, index) => (
                          <div
                            className="bg-[#F3F5F7] rounded-xl px-5 py-5 w-full my-2 flex flex-row items-center"
                            key={file.name}
                          >
                            <div className=" rounded-full  bg-white text-[#81838E] text-center p-2 shadow-1 ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                class="size-4"
                              >
                                <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5H5.625Z" />
                                <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                              </svg>
                            </div>
                            <div className="flex-1 px-5 text-[#303030] text-sm">
                              <div className="whitespace-nowrap overflow-hidden text-ellipsis max-w-full flex-shrink text-xs">
                                {file.name}
                              </div>
                              <div>
                                {(file.size / (1024 * 1024)).toFixed(2)} mb
                              </div>
                            </div>
                            <button
                              onClick={() => {
                                setFilesInitialMedicalReports((prevFiles) =>
                                  prevFiles.filter(
                                    (_, indexToRemove) =>
                                      index !== indexToRemove
                                  )
                                );
                              }}
                              className="rounded-full p-1 bg-[#E6E7E8] font-bold text-[#9D9D9D]"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="size-5"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M6 18 18 6M6 6l12 12"
                                />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    </aside>
                  </div>
                  {/* Save & Continue - step 3 */}
                  <div className="flex flex-row items-center justify-end my-3">
                    <button
                      onClick={() => setStepSelect(1)}
                      className="bg-white text-[#0388A6] text-sm px-5 py-3 rounded-full mr-3"
                    >
                      Back
                    </button>
                    <button
                      onClick={() => setStepSelect(3)}
                      className="text-white bg-[#0388A6] text-sm px-5 py-3 rounded-full"
                    >
                      Save & Continue
                    </button>
                  </div>
                </div>
              ) : null}
              {/* step 4 */}
              {stepSelect === 3 ? (
                <div className="">
                  <div className="text-[#0388A6] font-semibold text-xl">
                    Invoices
                  </div>
                  {/* Invoice Information: */}
                  <div className="text-xs font-medium mt-5 mb-2 text-black">
                    Invoice Information:
                  </div>
                  <div className="my-2 bg-white py-4 px-2 rounded-md">
                    <div className="flex md:flex-row flex-col  ">
                      <div className="md:w-1/2 w-full  md:pr-1 my-1">
                        <div className="text-[#B4B4B4] text-xs  mb-1">
                          Invoice Number (Optional)
                        </div>
                        <div>
                          <input
                            className=" outline-none border border-[#F1F3FF] px-3 py-2 w-full rounded text-sm"
                            type="text"
                            placeholder="Invoice Number (Optional)"
                            value={invoiceNumber}
                            onChange={(v) => setInvoiceNumber(v.target.value)}
                          />
                        </div>
                      </div>
                      {/*  */}
                      <div className="md:w-1/2 w-full  md:pr-1 my-1">
                        <div className="text-[#B4B4B4] text-xs  mb-1">
                          Date Issued (Optional)
                        </div>
                        <div>
                          <input
                            className=" outline-none border border-[#F1F3FF] px-3 py-2 w-full rounded text-sm"
                            type="date"
                            placeholder="Date Issued (Optional)"
                            value={dateIssued}
                            onChange={(v) => setDateIssued(v.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    {/*  */}
                    <div className="flex md:flex-row flex-col  ">
                      <div className="w-full  md:pr-1 my-1">
                        <div className="text-[#B4B4B4] text-xs  mb-1">
                          Amount (Optional)
                        </div>
                        <div>
                          <input
                            className=" outline-none border border-[#F1F3FF] px-3 py-2 w-full rounded text-sm"
                            type="number"
                            placeholder="Amount (Optional)"
                            value={amount}
                            onChange={(v) => setAmount(v.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-xs font-medium mt-5 mb-2 text-black">
                    Upload Invoice
                  </div>
                  <div className="my-2 bg-white py-4 px-2 rounded-md">
                    <div
                      {...getRootPropsUploadInvoice({ className: "dropzone" })}
                      // style={dropzoneStyle}
                      className="bg-[#F5F6FF] w-full min-h-50 flex flex-col items-center justify-center"
                    >
                      <input {...getInputPropsUploadInvoice()} />
                      <div className="my-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="size-8 p-2 bg-[#0388A6] rounded-full text-white"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                          />
                        </svg>
                      </div>
                      <div className="my-2">
                        Drag & Drop Image File or BROWSE
                      </div>
                    </div>
                    <aside style={thumbsContainer}>
                      <div className="w-full flex flex-col ">
                        {itemsUploadInvoice
                          ?.filter((file) => !fileDeleted.includes(file.id))
                          .map((file, index) => (
                            <div
                              className="bg-[#F3F5F7] rounded-xl px-5 py-5 w-full my-2 flex flex-row items-center"
                              key={file.file_name}
                            >
                              <div className=" rounded-full  bg-white text-[#81838E] text-center p-2 shadow-1 ">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  class="size-4"
                                >
                                  <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5H5.625Z" />
                                  <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                                </svg>
                              </div>
                              <div className="flex-1 px-5 text-[#303030] text-sm">
                                <div className="whitespace-nowrap overflow-hidden text-ellipsis max-w-full flex-shrink text-xs">
                                  {file.file_name}
                                </div>
                                <div>
                                  {parseFloat(file.file_size).toFixed(2)} mb
                                </div>
                              </div>
                              <button
                                onClick={() => {
                                  setFileDeleted([...fileDeleted, file.id]);
                                }}
                                className="rounded-full p-1 bg-[#E6E7E8] font-bold text-[#9D9D9D]"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="size-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M6 18 18 6M6 6l12 12"
                                  />
                                </svg>
                              </button>
                            </div>
                          ))}
                        {filesUploadInvoice?.map((file, index) => (
                          <div
                            className="bg-[#F3F5F7] rounded-xl px-5 py-5 w-full my-2 flex flex-row items-center"
                            key={file.name}
                          >
                            <div className=" rounded-full  bg-white text-[#81838E] text-center p-2 shadow-1 ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                class="size-4"
                              >
                                <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5H5.625Z" />
                                <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                              </svg>
                            </div>
                            <div className="flex-1 px-5 text-[#303030] text-sm">
                              <div className="whitespace-nowrap overflow-hidden text-ellipsis max-w-full flex-shrink text-xs">
                                {file.name}
                              </div>
                              <div>
                                {(file.size / (1024 * 1024)).toFixed(2)} mb
                              </div>
                            </div>
                            <button
                              onClick={() => {
                                setFilesUploadInvoice((prevFiles) =>
                                  prevFiles.filter(
                                    (_, indexToRemove) =>
                                      index !== indexToRemove
                                  )
                                );
                              }}
                              className="rounded-full p-1 bg-[#E6E7E8] font-bold text-[#9D9D9D]"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="size-5"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M6 18 18 6M6 6l12 12"
                                />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    </aside>
                  </div>

                  {/* Save & Continue - step 4 */}
                  <div className="flex flex-row items-center justify-end my-3">
                    <button
                      onClick={() => setStepSelect(2)}
                      className="bg-white text-[#0388A6] text-sm px-5 py-3 rounded-full mr-3"
                    >
                      Back
                    </button>
                    <button
                      onClick={() => setStepSelect(4)}
                      className="text-white bg-[#0388A6] text-sm px-5 py-3 rounded-full"
                    >
                      Save & Continue
                    </button>
                  </div>
                </div>
              ) : null}
              {/* step 5 */}
              {stepSelect === 4 ? (
                <div className="">
                  <div className="text-[#0388A6] font-semibold text-xl">
                    Insurance Authorization
                  </div>
                  {/* Insurance Details: */}
                  <div className="text-xs font-medium mt-5 mb-2 text-black">
                    Insurance Details:
                  </div>
                  <div className="my-2 bg-white py-4 px-2 rounded-md">
                    <div className="flex md:flex-row flex-col  ">
                      <div className="md:w-1/2 w-full  md:pr-1 my-1">
                        <div className="text-[#B4B4B4] text-xs  mb-1">
                          Insurance Company Name
                        </div>
                        <div>
                          <Select
                            value={insuranceCompany}
                            onChange={(option) => {
                              setInsuranceCompany(option);
                            }}
                            options={insurances?.map((assurance) => ({
                              value: assurance.id,
                              label: assurance.assurance_name || "",
                            }))}
                            filterOption={(option, inputValue) =>
                              option.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                            }
                            className="text-sm"
                            placeholder="Select Insurance..."
                            isSearchable
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                background: "#fff",
                                border: insuranceCompanyError
                                  ? "1px solid #d34053"
                                  : "1px solid #F1F3FF",
                                boxShadow: state.isFocused ? "none" : "none",
                                "&:hover": {
                                  border: "1px solid #F1F3FF",
                                },
                              }),
                              option: (base) => ({
                                ...base,
                                display: "flex",
                                alignItems: "center",
                              }),
                              singleValue: (base) => ({
                                ...base,
                                display: "flex",
                                alignItems: "center",
                              }),
                            }}
                          />
                        </div>
                      </div>
                      {/*  */}
                      <div className="md:w-1/2 w-full  md:pr-1 my-1">
                        <div className="text-[#B4B4B4] text-xs  mb-1">
                          Policy Number
                        </div>
                        <div>
                          <input
                            className=" outline-none border border-[#F1F3FF] px-3 py-2 w-full rounded text-sm"
                            type="text"
                            placeholder="Policy Number"
                            value={policyNumber}
                            onChange={(v) => setPolicyNumber(v.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Authorization Status: */}
                  <div className="text-xs font-medium mt-5 mb-2 text-black">
                    Authorization Status:
                  </div>
                  <div className="my-2 bg-white py-4 px-2 rounded-md">
                    <div className="flex md:flex-row flex-col  ">
                      <div className="w-full  md:pr-1 my-1">
                        <div className="text-[#B4B4B4] text-xs  mb-1">
                          Initial Status
                        </div>
                        <div>
                          <select
                            value={initialStatus}
                            onChange={(v) => setInitialStatus(v.target.value)}
                            className=" outline-none border border-[#F1F3FF] px-3 py-2 w-full rounded text-sm"
                          >
                            <option value={""}>Select Status</option>
                            <option value={"Pending"}>Pending</option>
                            <option value={"Approved"}>Approved</option>
                            <option value={"Denied"}>Denied</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Upload Authorization Documents */}
                  <div className="text-xs font-medium mt-5 mb-2 text-black">
                    Upload Authorization Documents
                  </div>
                  <div className="my-2 bg-white py-4 px-2 rounded-md">
                    <div
                      {...getRootPropsUploadAuthorizationDocuments({
                        className: "dropzone",
                      })}
                      // style={dropzoneStyle}
                      className="bg-[#F5F6FF] w-full min-h-50 flex flex-col items-center justify-center"
                    >
                      <input {...getInputPropsUploadAuthorizationDocuments()} />
                      <div className="my-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="size-8 p-2 bg-[#0388A6] rounded-full text-white"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                          />
                        </svg>
                      </div>
                      <div className="my-2">
                        Drag & Drop Image File or BROWSE
                      </div>
                    </div>
                    <aside style={thumbsContainer}>
                      <div className="w-full flex flex-col ">
                        {itemsUploadAuthorizationDocuments
                          ?.filter((file) => !fileDeleted.includes(file.id))
                          .map((file, index) => (
                            <div
                              className="bg-[#F3F5F7] rounded-xl px-5 py-5 w-full my-2 flex flex-row items-center"
                              key={file.file_name}
                            >
                              <div className=" rounded-full  bg-white text-[#81838E] text-center p-2 shadow-1 ">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  class="size-4"
                                >
                                  <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5H5.625Z" />
                                  <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                                </svg>
                              </div>
                              <div className="flex-1 px-5 text-[#303030] text-sm">
                                <div className="whitespace-nowrap overflow-hidden text-ellipsis max-w-full flex-shrink text-xs">
                                  {file.file_name}
                                </div>
                                <div>
                                  {parseFloat(file.file_size).toFixed(2)} mb
                                </div>
                              </div>
                              <button
                                onClick={() => {
                                  setFileDeleted([...fileDeleted, file.id]);
                                }}
                                className="rounded-full p-1 bg-[#E6E7E8] font-bold text-[#9D9D9D]"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="size-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M6 18 18 6M6 6l12 12"
                                  />
                                </svg>
                              </button>
                            </div>
                          ))}
                        {filesUploadAuthorizationDocuments?.map(
                          (file, index) => (
                            <div
                              className="bg-[#F3F5F7] rounded-xl px-5 py-5 w-full my-2 flex flex-row items-center"
                              key={file.name}
                            >
                              <div className=" rounded-full  bg-white text-[#81838E] text-center p-2 shadow-1 ">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  class="size-4"
                                >
                                  <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5H5.625Z" />
                                  <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                                </svg>
                              </div>
                              <div className="flex-1 px-5 text-[#303030] text-sm">
                                <div className="whitespace-nowrap overflow-hidden text-ellipsis max-w-full flex-shrink text-xs">
                                  {file.name}
                                </div>
                                <div>
                                  {(file.size / (1024 * 1024)).toFixed(2)} mb
                                </div>
                              </div>
                              <button
                                onClick={() => {
                                  setFilesUploadAuthorizationDocuments(
                                    (prevFiles) =>
                                      prevFiles.filter(
                                        (_, indexToRemove) =>
                                          index !== indexToRemove
                                      )
                                  );
                                }}
                                className="rounded-full p-1 bg-[#E6E7E8] font-bold text-[#9D9D9D]"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="size-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M6 18 18 6M6 6l12 12"
                                  />
                                </svg>
                              </button>
                            </div>
                          )
                        )}
                      </div>
                    </aside>
                  </div>
                  {/* Save & Continue - step 5 */}
                  <div className="flex flex-row items-center justify-end my-3">
                    <button
                      onClick={() => setStepSelect(3)}
                      className="bg-white text-[#0388A6] text-sm px-5 py-3 rounded-full mr-3"
                    >
                      Back
                    </button>
                    <button
                      disabled={loadingCaseUpdate}
                      onClick={async () => {
                        const providerItems = providerMultiSelect.map(
                          (item) => ({
                            service: item.service?.id,
                            provider: item.provider?.id,
                          })
                        );
                        // update
                        await dispatch(
                          updateCase(id, {
                            first_name: firstName,
                            last_name: lastName,
                            full_name: firstName + " " + lastName,
                            birth_day: birthDate ?? "",
                            patient_phone: phone,
                            patient_email: email,
                            patient_address: address,
                            patient_city: city,
                            patient_country: country.value,
                            //
                            coordinator: coordinator.value ?? "",
                            case_date: caseDate,
                            case_type: caseType,
                            case_description: caseDescription,
                            //
                            status_coordination: coordinatStatus,
                            case_status: coordinatStatusList,
                            appointment_date: appointmentDate,
                            service_location: serviceLocation,
                            provider: providerName.value ?? "",
                            //
                            invoice_number: invoiceNumber,
                            date_issued: dateIssued,
                            invoice_amount: amount,
                            assurance: insuranceCompany.value ?? "",
                            assurance_number: insuranceNumber,
                            policy_number: policyNumber,
                            assurance_status: initialStatus,
                            // files
                            initial_medical_reports: filesInitialMedicalReports,
                            upload_invoice: filesUploadInvoice,
                            upload_authorization_documents:
                              filesUploadAuthorizationDocuments,
                            files_deleted: fileDeleted,
                            providers: providerItems ?? [],
                            providers_deleted: providerMultiSelectDelete ?? [],
                            //
                            is_pay: isPay ? "True" : "False",
                            price_tatal: priceTotal,
                            currency_price: currencyCode.value ?? "",
                          })
                        );
                      }}
                      className="text-white bg-[#0388A6] text-sm px-5 py-3 rounded-full"
                    >
                      {loadingCaseUpdate ? "Loading.." : "Update"}
                    </button>
                  </div>
                </div>
              ) : null}
              {/* step 6 */}
              {stepSelect === 5 ? (
                <div className="">
                  <div className="my-2 bg-white py-4 px-2 rounded-md">
                    <div className="min-h-30 flex flex-col items-center justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="size-13 p-3 rounded-full text-[#0388A6] bg-[#0388A6] bg-opacity-10"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m4.5 12.75 6 6 9-13.5"
                        />
                      </svg>
                      <div className="my-5 font-semibold text-2xl text-black">
                        Case Updated Successfully!
                      </div>
                      <div className="text-base text-center md:w-2/3 mx-auto w-full px-3">
                        Your case has been successfully updates and saved. You
                        can now view the case details or create another case.
                      </div>
                      <div className="flex flex-row items-center justify-end my-3">
                        {/* <button
                          onClick={() => {
                            setStepSelect(4);
                          }}
                          className="text-white bg-[#0388A6] text-sm px-5 py-3 rounded-full"
                        >
                          Go to Dahboard
                        </button> */}
                        <a
                          href="/dashboard"
                          className="text-white bg-[#0388A6] text-sm px-5 py-3 rounded-full"
                        >
                          Go to Dahboard
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default EditCaseScreen;
