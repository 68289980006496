export const INSURANCE_LIST_REQUEST = "INSURANCE_LIST_REQUEST";
export const INSURANCE_LIST_SUCCESS = "INSURANCE_LIST_SUCCESS";
export const INSURANCE_LIST_FAIL = "INSURANCE_LIST_FAIL";

export const INSURANCE_ADD_REQUEST = "INSURANCE_ADD_REQUEST";
export const INSURANCE_ADD_SUCCESS = "INSURANCE_ADD_SUCCESS";
export const INSURANCE_ADD_FAIL = "INSURANCE_ADD_FAIL";

export const INSURANCE_DETAIL_REQUEST = "INSURANCE_DETAIL_REQUEST";
export const INSURANCE_DETAIL_SUCCESS = "INSURANCE_DETAIL_SUCCESS";
export const INSURANCE_DETAIL_FAIL = "INSURANCE_DETAIL_FAIL";

export const INSURANCE_UPDATE_REQUEST = "INSURANCE_UPDATE_REQUEST";
export const INSURANCE_UPDATE_SUCCESS = "INSURANCE_UPDATE_SUCCESS";
export const INSURANCE_UPDATE_FAIL = "INSURANCE_UPDATE_FAIL";

export const INSURANCE_DELETE_REQUEST = "INSURANCE_DELETE_REQUEST";
export const INSURANCE_DELETE_SUCCESS = "INSURANCE_DELETE_SUCCESS";
export const INSURANCE_DELETE_FAIL = "INSURANCE_DELETE_FAIL";
