import { toast } from "react-toastify";

import {
  INSURANCE_LIST_REQUEST,
  INSURANCE_LIST_SUCCESS,
  INSURANCE_LIST_FAIL,
  //
  INSURANCE_ADD_REQUEST,
  INSURANCE_ADD_SUCCESS,
  INSURANCE_ADD_FAIL,
  //
  INSURANCE_DETAIL_REQUEST,
  INSURANCE_DETAIL_SUCCESS,
  INSURANCE_DETAIL_FAIL,
  //
  INSURANCE_UPDATE_REQUEST,
  INSURANCE_UPDATE_SUCCESS,
  INSURANCE_UPDATE_FAIL,
  //
  INSURANCE_DELETE_REQUEST,
  INSURANCE_DELETE_SUCCESS,
  INSURANCE_DELETE_FAIL,
  //
} from "../constants/insuranceConstants";

export const updateInsuranceReducer = (state = {}, action) => {
  switch (action.type) {
    case INSURANCE_UPDATE_REQUEST:
      return { loadingInsuranceUpdate: true };
    case INSURANCE_UPDATE_SUCCESS:
      toast.success("This Insurance has been updated successfully.");
      return {
        loadingInsuranceUpdate: false,
        successInsuranceUpdate: true,
      };
    case INSURANCE_UPDATE_FAIL:
      toast.error(action.payload);
      return {
        loadingInsuranceUpdate: false,
        successInsuranceUpdate: false,
        errorInsuranceUpdate: action.payload,
      };
    default:
      return state;
  }
};

export const detailInsuranceReducer = (
  state = { insuranceInfo: {} },
  action
) => {
  switch (action.type) {
    case INSURANCE_DETAIL_REQUEST:
      return { loadingInsuranceInfo: true };
    case INSURANCE_DETAIL_SUCCESS:
      return {
        loadingInsuranceInfo: false,
        successInsuranceInfo: true,
        insuranceInfo: action.payload,
      };
    case INSURANCE_DETAIL_FAIL:
      return {
        loadingInsuranceInfo: false,
        successInsuranceInfo: false,
        errorInsuranceInfo: action.payload,
      };
    default:
      return state;
  }
};

export const deleteInsuranceReducer = (state = {}, action) => {
  switch (action.type) {
    case INSURANCE_DELETE_REQUEST:
      return { loadingInsuranceDelete: true };
    case INSURANCE_DELETE_SUCCESS:
      toast.success("This Insurance has been successfully deleted.");
      return {
        loadingInsuranceDelete: false,
        successInsuranceDelete: true,
      };
    case INSURANCE_DELETE_FAIL:
      toast.error(action.payload);
      return {
        loadingInsuranceDelete: false,
        successInsuranceDelete: false,
        errorInsuranceDelete: action.payload,
      };
    default:
      return state;
  }
};

export const addNewInsuranceReducer = (state = {}, action) => {
  switch (action.type) {
    case INSURANCE_ADD_REQUEST:
      return { loadingInsuranceAdd: true };
    case INSURANCE_ADD_SUCCESS:
      toast.success("This Insurance has been added successfully");
      return {
        loadingInsuranceAdd: false,
        successInsuranceAdd: true,
      };
    case INSURANCE_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingInsuranceAdd: false,
        successInsuranceAdd: false,
        errorInsuranceAdd: action.payload,
      };
    default:
      return state;
  }
};

export const insuranceListReducer = (state = { insurances: [] }, action) => {
  switch (action.type) {
    case INSURANCE_LIST_REQUEST:
      return { loadingInsurances: true, insurances: [] };
    case INSURANCE_LIST_SUCCESS:
      return {
        loadingInsurances: false,
        insurances: action.payload.insurances,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case INSURANCE_LIST_FAIL:
      return { loadingInsurances: false, errorInsurances: action.payload };
    default:
      return state;
  }
};
