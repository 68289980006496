import { toast } from "react-toastify";
import {
  PROVIDER_LIST_REQUEST,
  PROVIDER_LIST_SUCCESS,
  PROVIDER_LIST_FAIL,
  //
  PROVIDER_ADD_REQUEST,
  PROVIDER_ADD_SUCCESS,
  PROVIDER_ADD_FAIL,
  //
  PROVIDER_DETAIL_REQUEST,
  PROVIDER_DETAIL_SUCCESS,
  PROVIDER_DETAIL_FAIL,
  //
  PROVIDER_UPDATE_REQUEST,
  PROVIDER_UPDATE_SUCCESS,
  PROVIDER_UPDATE_FAIL,
  //
  PROVIDER_DELETE_REQUEST,
  PROVIDER_DELETE_SUCCESS,
  PROVIDER_DELETE_FAIL,
  //
} from "../constants/providerConstants";

export const updateProviderReducer = (state = {}, action) => {
  switch (action.type) {
    case PROVIDER_UPDATE_REQUEST:
      return { loadingProviderUpdate: true };
    case PROVIDER_UPDATE_SUCCESS:
      toast.success("This Provider has been updated successfully.");
      return {
        loadingProviderUpdate: false,
        successProviderUpdate: true,
      };
    case PROVIDER_UPDATE_FAIL:
      toast.error(action.payload);
      return {
        loadingProviderUpdate: false,
        successProviderUpdate: false,
        errorProviderUpdate: action.payload,
      };
    default:
      return state;
  }
};

export const deleteProviderReducer = (state = {}, action) => {
  switch (action.type) {
    case PROVIDER_DELETE_REQUEST:
      return { loadingProviderDelete: true };
    case PROVIDER_DELETE_SUCCESS:
      toast.success("This Provider has been successfully deleted.");
      return {
        loadingProviderDelete: false,
        successProviderDelete: true,
      };
    case PROVIDER_DELETE_FAIL:
      toast.error(action.payload);
      return {
        loadingProviderDelete: false,
        successProviderDelete: false,
        errorProviderDelete: action.payload,
      };
    default:
      return state;
  }
};

export const addNewProviderReducer = (state = {}, action) => {
  switch (action.type) {
    case PROVIDER_ADD_REQUEST:
      return { loadingProviderAdd: true };
    case PROVIDER_ADD_SUCCESS:
      toast.success("This Provider has been added successfully");
      return {
        loadingProviderAdd: false,
        successProviderAdd: true,
      };
    case PROVIDER_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingProviderAdd: false,
        successProviderAdd: false,
        errorProviderAdd: action.payload,
      };
    default:
      return state;
  }
};

export const detailProviderReducer = (state = { providerInfo: {} }, action) => {
  switch (action.type) {
    case PROVIDER_DETAIL_REQUEST:
      return { loadingProviderInfo: true };
    case PROVIDER_DETAIL_SUCCESS:
      return {
        loadingProviderInfo: false,
        successProviderInfo: true,
        providerInfo: action.payload,
      };
    case PROVIDER_DETAIL_FAIL:
      return {
        loadingProviderInfo: false,
        successProviderInfo: false,
        errorProviderInfo: action.payload,
      };
    default:
      return state;
  }
};

export const providerListReducer = (state = { providers: [] }, action) => {
  switch (action.type) {
    case PROVIDER_LIST_REQUEST:
      return { loadingProviders: true, providers: [] };
    case PROVIDER_LIST_SUCCESS:
      return {
        loadingProviders: false,
        providers: action.payload.providers,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case PROVIDER_LIST_FAIL:
      return { loadingProviders: false, errorProviders: action.payload };
    default:
      return state;
  }
};
