import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { detailClient, updateClient } from "../../redux/actions/clientActions";
import CountrySelector from "../../components/Selector";
import { COUNTRIES, baseURLFile } from "../../constants";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import InputModel from "../../components/InputModel";
import ConfirmationModal from "../../components/ConfirmationModal";
import LayoutSection from "../../components/LayoutSection";

function EditClientScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  let { id } = useParams();
  //
  const [firstName, setFirstName] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");

  const [country, setCountry] = useState("");
  const [errorCountry, setErrorCountry] = useState("");
  const [city, setCity] = useState("");
  const [errorCity, setErrorCity] = useState("");
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState("");

  const [isUpdate, setIsUpdate] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  const [eventType, setEventType] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const clientDetail = useSelector((state) => state.detailClient);
  const { loading, error, success, client } = clientDetail;

  const clientUpdate = useSelector((state) => state.updateClient);
  const { loadingClientUpdate, errorClientUpdate, successClientUpdate } =
    clientUpdate;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(detailClient(id));
    }
  }, [navigate, userInfo, dispatch, id]);

  useEffect(() => {
    if (client !== undefined && client !== null) {
      setFirstName(client.first_name === null ? "" : client.first_name);
      setLastName(client.last_name === null ? "" : client.last_name);
      setCountry("MA");
      setCountry(client.country);
      console.log(country);
      setCity(client.city === null ? "" : client.city);
      setEmail(client.email === null ? "" : client.email);
      setPhone(client.phone === null ? "" : client.phone);
    }
  }, [client]);

  useEffect(() => {
    if (successClientUpdate) {
      dispatch(detailClient(id));
    }
  }, [successClientUpdate]);

  return (
    <DefaultLayout>
      <div>
        {/* top dash */}
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/clients/">
            <div className="">Clients</div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Modification</div>
        </div>

        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Update Client
            </h4>
          </div>
          {/*  */}

          {loading ? (
            <Loader />
          ) : error ? (
            <Alert type="error" message={error} />
          ) : client ? (
            <>
              <div className="flex md:flex-row flex-col ">
                <div className="w-full px-1 py-1">
                  <LayoutSection title="Informations personnelles">
                    {/* fisrt name & last name */}
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="First Name"
                        type="text"
                        placeholder=""
                        value={firstName}
                        onChange={(v) => setFirstName(v.target.value)}
                        error={errorFirstName}
                      />

                      <InputModel
                        label="Last Name"
                        type="text"
                        placeholder=""
                        value={lastName}
                        onChange={(v) => setLastName(v.target.value)}
                        error={errorLastName}
                      />
                    </div>
                    {/* date and nation */}
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Country"
                        type="select"
                        placeholder=""
                        value={country}
                        onChange={(v) => {
                          setCountry(v.target.value);
                        }}
                        error={errorCountry}
                        options={COUNTRIES?.map((country) => ({
                          value: country.value,
                          label: country.title,
                        }))}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="City"
                        type="text"
                        placeholder=""
                        value={city}
                        onChange={(v) => setCity(v.target.value)}
                        error={errorCity}
                      />
                    </div>

                    {/* gsm and mail */}
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Phone"
                        type="text"
                        placeholder=""
                        value={phone}
                        onChange={(v) => setPhone(v.target.value)}
                        error={errorPhone}
                      />
                      <InputModel
                        label="Email"
                        type="email"
                        placeholder=""
                        value={email}
                        onChange={(v) => setEmail(v.target.value)}
                        error={errorEmail}
                      />
                    </div>
                  </LayoutSection>
                </div>
              </div>
              <div className="my-2 flex flex-row items-center justify-end">
                <button
                  onClick={() => {
                    setEventType("cancel");
                    setIsUpdate(true);
                  }}
                  className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
                >
                  Annuler
                </button>
                <button
                  onClick={async () => {
                    var check = true;
                    setErrorFirstName("");
                    setErrorLastName("");
                    setErrorCountry("");
                    setErrorCity("");

                    setErrorEmail("");
                    setErrorPhone("");

                    if (firstName === "") {
                      setErrorFirstName("Ce champ est requis.");
                      check = false;
                    }
                    if (lastName === "") {
                      setErrorLastName("Ce champ est requis.");
                      check = false;
                    }
                    if (city === "") {
                      setErrorCity("Ce champ est requis.");
                      check = false;
                    }
                    if (country === "") {
                      setErrorCountry("Ce champ est requis.");
                      check = false;
                    }
                    if (phone === "") {
                      setErrorPhone("Ce champ est requis.");
                      check = false;
                    }

                    if (check) {
                      setEventType("add");
                      setIsUpdate(true);
                    } else {
                      toast.error(
                        "Certains champs sont obligatoires veuillez vérifier"
                      );
                    }
                  }}
                  className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                    />
                  </svg>
                  Modifié
                </button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        <ConfirmationModal
          isOpen={isUpdate}
          message={
            eventType === "cancel"
              ? "Êtes-vous sûr de vouloir annuler cette information ?"
              : "Êtes-vous sûr de vouloir ajouter ce Client ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setErrorFirstName("");
              setErrorLastName("");
              setErrorCountry("");
              setErrorCity("");

              setErrorEmail("");
              setErrorPhone("");

              dispatch(detailClient(id));

              setIsUpdate(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setLoadEvent(true);

              await dispatch(
                updateClient(id, {
                  first_name: firstName,
                  last_name: lastName,
                  full_name: firstName + " " + lastName,

                  country: country,
                  city: city,
                  phone: phone,
                  email: email,
                })
              ).then(() => {});
              setLoadEvent(false);

              setIsUpdate(false);
              setEventType("");
              setLoadEvent(false);
            }
          }}
          onCancel={() => {
            setIsUpdate(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />

        {/* buttom dash */}
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default EditClientScreen;
