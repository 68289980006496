import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LogoutSaved } from "../../redux/actions/userActions";

function LogoutScreen() {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, error, loading } = userLogin;

  const userLogoutSaved = useSelector((state) => state.logoutSavedUser);
  const { loadingUserLogout, errorUserLogout, successUserLogout } =
    userLogoutSaved;

  useEffect(() => {
    dispatch(LogoutSaved());
  }, [dispatch]);

  useEffect(() => {
    if (successUserLogout) {
      localStorage.removeItem("userInfoUnimedCare");
      document.location.href = "/";
    }
  }, [successUserLogout]);
  return <div></div>;
}

export default LogoutScreen;
