import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import DefaultLayout from "../../layouts/DefaultLayout";
import { toast } from "react-toastify";
import { createNewInsurance } from "../../redux/actions/insuranceActions";
import { COUNTRIES } from "../../constants";
import Select from "react-select";

function AddInsuranceScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [loadEvent, setLoadEvent] = useState(false);

  const [insuranceName, setInsuranceName] = useState("");
  const [insuranceNameError, setInsuranceNameError] = useState("");

  const [insuranceCountry, setInsuranceCountry] = useState("");
  const [insuranceCountryError, setInsuranceCountryError] = useState("");

  const [insuranceEmail, setInsuranceEmail] = useState("");
  const [insuranceEmailError, setInsuranceEmailError] = useState("");

  const [insuranceEmailTwo, setInsuranceEmailTwo] = useState("");
  const [insuranceEmailTwoError, setInsuranceEmailTwoError] = useState("");

  const [insuranceEmailThree, setInsuranceEmailThree] = useState("");
  const [insuranceEmailThreeError, setInsuranceEmailThreeError] = useState("");

  const [insurancePhone, setInsurancePhone] = useState("");
  const [insurancePhoneError, setInsurancePhoneError] = useState("");

  const [insurancePhoneTwo, setInsurancePhoneTwo] = useState("");
  const [insurancePhoneTwoError, setInsurancePhoneTwoError] = useState("");

  const [insurancePhoneThree, setInsurancePhoneThree] = useState("");
  const [insurancePhoneThreeError, setInsurancePhoneThreeError] = useState("");

  const [insuranceLogo, setInsuranceLogo] = useState("");
  const [insuranceLogoValue, setInsuranceLogoValue] = useState("");
  const [insuranceLogoError, setInsuranceLogoError] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;

  const insuranceAdd = useSelector((state) => state.addNewInsurance);
  const { loadingInsuranceAdd, errorInsuranceAdd, successInsuranceAdd } =
    insuranceAdd;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else if (
      userInfo.role !== "1" &&
      userInfo.role !== 1 &&
      userInfo.role !== "2" &&
      userInfo.role !== 2
    ) {
      navigate(redirect);
    }
  }, [navigate, userInfo, dispatch]);

  useEffect(() => {
    if (successInsuranceAdd) {
      setInsuranceName("");
      setInsuranceNameError("");
      setInsuranceCountry("");
      setInsuranceCountryError("");
      setInsuranceEmail("");
      setInsuranceEmailError("");
      setInsuranceEmailTwo("");
      setInsuranceEmailTwoError("");
      setInsuranceEmailThree("");
      setInsuranceEmailThreeError("");
      setInsurancePhone("");
      setInsurancePhoneError("");
      setInsurancePhoneTwo("");
      setInsurancePhoneTwoError("");
      setInsurancePhoneThree("");
      setInsurancePhoneThreeError("");
      setInsuranceLogo("");
      setInsuranceLogoError("");
      setInsuranceLogoValue("");
    }
  }, [successInsuranceAdd]);

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
          <a href="/insurances-company">
            <div className="flex flex-row  items-center hover:text-black ">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m8.25 4.5 7.5 7.5-7.5 7.5"
                  />
                </svg>
              </span>
              <div className="">Insurances Company</div>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Create New Insurances</div>
        </div>
        {/*  */}
        <div className="py-5 px-4 flex justify-between">
          <h4 className=" uppercase font-semibold text-black dark:text-white">
            New Insurances
          </h4>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="my-2 bg-white py-4 px-2 rounded-md">
            <div className="flex md:flex-row flex-col  ">
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Insurance Name <strong className="text-danger">*</strong>
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      insuranceNameError ? "border-danger" : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="text"
                    placeholder="Insurance Name"
                    value={insuranceName}
                    onChange={(v) => setInsuranceName(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger">
                    {insuranceNameError ? insuranceNameError : ""}
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Insurance Country
                </div>
                <div>
                  <Select
                    value={insuranceCountry}
                    onChange={(option) => {
                      setInsuranceCountry(option);
                    }}
                    options={COUNTRIES.map((country) => ({
                      value: country.title,
                      label: (
                        <div
                          className={`${
                            country.title === "" ? "py-2" : ""
                          } flex flex-row items-center`}
                        >
                          <span className="mr-2">{country.icon}</span>
                          <span>{country.title}</span>
                        </div>
                      ),
                    }))}
                    className="text-sm"
                    placeholder="Select a country..."
                    isSearchable
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        background: "#fff",
                        border: insuranceCountryError
                          ? "1px solid #d34053"
                          : "1px solid #F1F3FF",
                        boxShadow: state.isFocused ? "none" : "none",
                        "&:hover": {
                          border: "1px solid #F1F3FF",
                        },
                      }),
                      option: (base) => ({
                        ...base,
                        display: "flex",
                        alignItems: "center",
                      }),
                      singleValue: (base) => ({
                        ...base,
                        display: "flex",
                        alignItems: "center",
                      }),
                    }}
                  />

                  <div className=" text-[8px] text-danger">
                    {insuranceCountryError ? insuranceCountryError : ""}
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="flex md:flex-row flex-col  ">
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Insurance Email 1
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      insuranceEmailError ? "border-danger" : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="text"
                    placeholder="Insurance Email 1"
                    value={insuranceEmail}
                    onChange={(v) => setInsuranceEmail(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger">
                    {insuranceEmailError ? insuranceEmailError : ""}
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Insurance Phone 1
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      insurancePhoneError ? "border-danger" : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="text"
                    placeholder="Insurance Phone 1"
                    value={insurancePhone}
                    onChange={(v) => setInsurancePhone(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger">
                    {insurancePhoneError ? insurancePhoneError : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex md:flex-row flex-col  ">
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Insurance Email 2
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      insuranceEmailTwoError
                        ? "border-danger"
                        : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="text"
                    placeholder="Insurance Email 2"
                    value={insuranceEmailTwo}
                    onChange={(v) => setInsuranceEmailTwo(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger">
                    {insuranceEmailTwoError ? insuranceEmailTwoError : ""}
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Insurance Phone 2
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      insurancePhoneTwoError
                        ? "border-danger"
                        : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="text"
                    placeholder="Insurance Phone 2"
                    value={insurancePhoneTwo}
                    onChange={(v) => setInsurancePhoneTwo(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger">
                    {insurancePhoneTwoError ? insurancePhoneTwoError : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex md:flex-row flex-col  ">
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Insurance Email 3
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      insuranceEmailThreeError
                        ? "border-danger"
                        : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="text"
                    placeholder="Insurance Email 3"
                    value={insuranceEmailThree}
                    onChange={(v) => setInsuranceEmailThree(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger">
                    {insuranceEmailThreeError ? insuranceEmailThreeError : ""}
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Insurance Phone 3
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      insurancePhoneThreeError
                        ? "border-danger"
                        : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="text"
                    placeholder="Insurance Phone 3"
                    value={insurancePhoneThree}
                    onChange={(v) => setInsurancePhoneThree(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger">
                    {insurancePhoneThreeError ? insurancePhoneThreeError : ""}
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="flex md:flex-row flex-col  ">
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Insurance Logo
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      insuranceLogoError ? "border-danger" : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="file"
                    placeholder="Insurance Logo"
                    value={insuranceLogoValue}
                    onChange={(v) => {
                      setInsuranceLogo(v.target.files[0]);
                      setInsuranceLogoValue(v.target.value);
                    }}
                  />
                  <div className=" text-[8px] text-danger">
                    {insuranceLogoError ? insuranceLogoError : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="my-3 ">
              <div className="flex flex-row items-center justify-end my-3">
                <a
                  href="/insurances-company"
                  className="bg-white text-[#0388A6] text-sm px-5 py-3 rounded-full mr-3"
                >
                  Back
                </a>
                <button
                  onClick={async () => {
                    var check = true;
                    setInsuranceNameError("");
                    setInsuranceCountryError("");
                    setInsuranceEmailError("");
                    setInsuranceEmailTwoError("");
                    setInsuranceEmailThreeError("");
                    setInsurancePhoneError("");
                    setInsurancePhoneTwoError("");
                    setInsurancePhoneThreeError("");
                    setInsuranceLogoError("");

                    if (insuranceName === "") {
                      setInsuranceNameError("These fields are required.");
                      check = false;
                    }

                    if (check) {
                      setLoadEvent(true);
                      await dispatch(
                        createNewInsurance({
                          assurance_name: insuranceName,
                          assurance_country: insuranceCountry.value ?? "",
                          assurance_phone: insurancePhone,
                          assurance_phone_two: insurancePhoneTwo,
                          assurance_phone_three: insurancePhoneThree,
                          assurance_email: insuranceEmail,
                          assurance_email_two: insuranceEmailTwo,
                          assurance_email_three: insuranceEmailThree,
                          assurance_logo: insuranceLogo,
                        })
                      ).then(() => {});
                      setLoadEvent(false);
                    } else {
                      toast.error(
                        "Some fields are empty or invalid. please try again"
                      );
                    }
                  }}
                  className="text-white bg-[#0388A6] text-sm px-5 py-3 rounded-full"
                >
                  {loadingInsuranceAdd ? "Loading ..." : "Create Insurance"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default AddInsuranceScreen;
