import "./App.css";
import "./axios.js";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginScreen from "./screens/auth/LoginScreen";
import LogoutScreen from "./screens/auth/LogoutScreen.js";
import DashboardScreen from "./screens/dashboard/DashboardScreen.js";
import CaseScreen from "./screens/cases/CaseScreen.js";
import DetailCaseScreen from "./screens/cases/DetailCaseScreen.js";
import KpisInformationScreen from "./screens/kpiinformations/KpisInformationScreen.js";
import AddCaseScreen from "./screens/cases/AddCaseScreen.js";
import ClientScreen from "./screens/clients/ClientScreen.js";
import AddClientScreen from "./screens/clients/AddClientScreen.js";
import EditClientScreen from "./screens/clients/EditClientScreen.js";
import EditCaseScreen from "./screens/cases/EditCaseScreen.js";
import ProvidersMapScreen from "./screens/proveedors/ProvidersMapScreen.js";
import CoordinatorSpaceScreen from "./screens/coordinator-space/CoordinatorSpaceScreen.js";
import SettingsScreen from "./screens/settings/SettingsScreen.js";
import HelpScreen from "./screens/help/HelpScreen.js";
import FaqScreen from "./screens/help/FaqScreen.js";
import ContactSupportScreen from "./screens/contact/ContactSupportScreen.js";
import AddProviderScreen from "./screens/proveedors/AddProviderScreen.js";
import InsurancesScreen from "./screens/insurances/InsurancesScreen.js";
import AddInsuranceScreen from "./screens/insurances/AddInsuranceScreen.js";
import EditProviderScreen from "./screens/proveedors/EditProviderScreen.js";
import EditInsuranceScreen from "./screens/insurances/EditInsuranceScreen.js";
import AddCoordinatorScreen from "./screens/coordinator-space/AddCoordinatorScreen.js";
import ProfileScreen from "./screens/profile/ProfileScreen.js";
import EditCoordinatorScreen from "./screens/coordinator-space/EditCoordinatorScreen.js";
import CoordinatorProfileScreen from "./screens/coordinator-space/CoordinatorProfileScreen.js";
import InsuranceProfileScreen from "./screens/insurances/InsuranceProfileScreen.js";
import ProviderProfileScreen from "./screens/proveedors/ProviderProfileScreen.js";
import ResetPasswordScreen from "./screens/auth/ResetPasswordScreen.js";
import ConfirmPasswordScreen from "./screens/auth/ConfirmPasswordScreen.js";
import SendResetPasswordScreen from "./screens/auth/SendResetPasswordScreen.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginScreen />,
  },
  {
    path: "/reset-password",
    element: <ResetPasswordScreen />,
  },
  {
    path: "/send-reset-password",
    element: <SendResetPasswordScreen />,
  },
  {
    path: "/confirm-password/:uidb64/:token/:timestamp",
    element: <ConfirmPasswordScreen />,
  },
  {
    path: "/dashboard",
    element: <DashboardScreen />,
  },
  {
    path: "/dashboard-old",
    element: <KpisInformationScreen />,
  },

  // clients
  {
    path: "/clients",
    element: <ClientScreen />,
  },
  {
    path: "/clients/add",
    element: <AddClientScreen />,
  },
  {
    path: "/clients/edit/:id",
    element: <EditClientScreen />,
  },
  // coordinator
  {
    path: "/coordinator-space",
    element: <CoordinatorSpaceScreen />,
  },
  {
    path: "/coordinator-space/new-coordinator",
    element: <AddCoordinatorScreen />,
  },
  {
    path: "/coordinator-space/edit/:id",
    element: <EditCoordinatorScreen />,
  },

  {
    path: "/coordinator-space/profile/:id",
    element: <CoordinatorProfileScreen />,
  },

  {
    path: "/settings",
    element: <SettingsScreen />,
  },
  {
    path: "/help",
    element: <HelpScreen />,
  },
  {
    path: "/faq",
    element: <FaqScreen />,
  },
  {
    path: "/contact-support",
    element: <ContactSupportScreen />,
  },
  //
  {
    path: "/profile",
    element: <ProfileScreen />,
  },

  // casos
  {
    path: "/cases-list",
    element: <CaseScreen />,
  },
  {
    path: "/cases",
    element: <CaseScreen />,
  },
  {
    path: "/cases-list/detail/:id",
    element: <DetailCaseScreen />,
  },
  {
    path: "/cases-list/edit/:id",
    element: <EditCaseScreen />,
  },
  {
    path: "/cases/edit/:id",
    element: <EditCaseScreen />,
  },
  {
    path: "/cases-list/add",
    element: <AddCaseScreen />,
  },

  {
    path: "/providers-list",
    element: <ProvidersMapScreen />,
  },
  {
    path: "/providers-list/new-provider",
    element: <AddProviderScreen />,
  },
  {
    path: "/providers-list/edit/:id",
    element: <EditProviderScreen />,
  },
  {
    path: "/providers-list/profile/:id",
    element: <ProviderProfileScreen />,
  },

  {
    path: "/kps-informations",
    element: <KpisInformationScreen />,
  },
  {
    path: "/insurances-company",
    element: <InsurancesScreen />,
  },
  {
    path: "/insurances-company/new-insurance",
    element: <AddInsuranceScreen />,
  },
  {
    path: "/insurances-company/edit/:id",
    element: <EditInsuranceScreen />,
  },
  {
    path: "/insurances-company/profile/:id",
    element: <InsuranceProfileScreen />,
  },

  {
    path: "/logout",
    element: <LogoutScreen />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
