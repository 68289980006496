import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  deleteInsurance,
  getInsuranesList,
} from "../../redux/actions/insuranceActions";
import DefaultLayout from "../../layouts/DefaultLayout";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import { baseURLFile } from "../../constants";
import ConfirmationModal from "../../components/ConfirmationModal";

function InsurancesScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const dispatch = useDispatch();

  const [isDelete, setIsDelete] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  const [eventType, setEventType] = useState("");
  const [insuranceId, setInsuranceId] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const listInsurances = useSelector((state) => state.insuranceList);
  const { insurances, loadingInsurances, errorInsurances, pages } =
    listInsurances;

  const insuranceDelete = useSelector((state) => state.deleteInsurance);
  const {
    loadingInsuranceDelete,
    errorInsuranceDelete,
    successInsuranceDelete,
  } = insuranceDelete;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else if (
      userInfo.role !== "1" &&
      userInfo.role !== 1 &&
      userInfo.role !== "2" &&
      userInfo.role !== 2
    ) {
      navigate(redirect);
    } else {
      dispatch(getInsuranesList("0"));
    }
  }, [navigate, userInfo, dispatch, page]);

  useEffect(() => {
    if (successInsuranceDelete) {
      dispatch(getInsuranesList("0"));
    }
  }, [successInsuranceDelete]);

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Insurance Company</div>
        </div>
        {/*  */}
        <div className="flex flex-row justify-between  items-center my-3">
          <div className="mx-1 font-bold text-black ">Insurances Company</div>

          <div className="flex flex-row items-center justify-end">
            <a
              href="/insurances-company/new-insurance"
              className="mx-2 flex flex-row bg-[#0388A6] text-white text-xs rounded-full px-5 py-3 items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-4 mx-1"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>

              <div>New Insurance</div>
            </a>
          </div>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default   dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="container mx-auto flex flex-col">
            {loadingInsurances ? (
              <Loader />
            ) : errorInsurances ? (
              <Alert type="error" message={errorInsurances} />
            ) : (
              <div className="max-w-full overflow-x-auto ">
                <table className="w-full table-auto">
                  <thead>
                    <tr className=" bg-[#F3F5FB] text-left ">
                      <th className="min-w-[30px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                        #
                      </th>
                      <th className="min-w-[60px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max ">
                        Logo
                      </th>
                      <th className="min-w-[60px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max ">
                        Insurance Name
                      </th>
                      <th className="min-w-[120px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                        Country
                      </th>
                      <th className="min-w-[120px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                        Email
                      </th>
                      <th className="min-w-[120px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                        Phone
                      </th>
                      <th className="py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                        Operation
                      </th>
                    </tr>
                  </thead>
                  {/*  */}
                  <tbody>
                    {insurances?.map((item, index) => (
                      <tr key={index}>
                        <td className=" py-3 px-4 min-w-[120px]  ">
                          <p className="text-black  text-xs w-max  ">
                            #{item.id}
                          </p>
                        </td>
                        <td className=" py-3 px-4 min-w-[120px]  ">
                          <a
                            href={"/insurances-company/profile/" + item.id}
                            // href={baseURLFile + item.assurance_logo}
                            className="text-black  text-xs w-max  "
                            // target="_blank"
                            // rel="noopener noreferrer"
                          >
                            {item.assurance_logo ? (
                              <img
                                className="size-11 rounded"
                                src={baseURLFile + item.assurance_logo}
                                alt={item.assurance_name}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = "/assets/placeholder.png";
                                }}
                              />
                            ) : (
                              <img
                                className="size-11 rounded"
                                alt={item.assurance_name}
                                src={"/assets/placeholder.png"}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = "/assets/placeholder.png";
                                }}
                              />
                            )}
                          </a>
                        </td>
                        <td className=" py-3 px-4 min-w-[120px]  ">
                          <p className="text-black  text-xs w-max  ">
                            {item.assurance_name ?? "---"}
                          </p>
                        </td>
                        <td className=" py-3 px-4 min-w-[120px]  ">
                          <p className="text-black  text-xs w-max  ">
                            {item.assurance_country ?? "---"}
                          </p>
                        </td>
                        <td className=" py-3 px-4 min-w-[120px]  ">
                          <p className="text-black  text-xs w-max  ">
                            {item.assurance_email ?? "---"}
                          </p>
                        </td>
                        <td className=" py-3 px-4 min-w-[120px]  ">
                          <p className="text-black  text-xs w-max  ">
                            {item.assurance_phone ?? "---"}
                          </p>
                        </td>
                        <td className=" py-3 px-4 min-w-[120px]  ">
                          <p className="text-black  text-xs w-max flex flex-row  ">
                            <Link
                              className="mx-1 update-class"
                              to={"/insurances-company/edit/" + item.id}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-5 h-5 bg-primary rounded p-1 text-white text-center text-xs"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                />
                              </svg>
                            </Link>
                            <div
                              onClick={() => {
                                setEventType("delete");
                                setInsuranceId(item.id);
                                setIsDelete(true);
                              }}
                              className="mx-1 delete-class cursor-pointer"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                                />
                              </svg>
                            </div>
                            <Link
                              className="mx-1 profile-class"
                              to={"/insurances-company/profile/" + item.id}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="w-5 h-5 bg-success rounded p-1 text-white text-center text-xs"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                                />
                              </svg>
                            </Link>
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        <ConfirmationModal
          isOpen={isDelete}
          message={
            eventType === "delete"
              ? "Are you sure you want to delete this Insurance?"
              : "Are you sure ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setIsDelete(false);
              setEventType("");
              setLoadEvent(false);
            } else if (eventType === "delete" && insuranceId !== "") {
              setLoadEvent(true);
              dispatch(deleteInsurance(insuranceId));
              setIsDelete(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setIsDelete(false);
              setEventType("");
              setLoadEvent(false);
            }
          }}
          onCancel={() => {
            setIsDelete(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default InsurancesScreen;
