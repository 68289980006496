import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const DropdownProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <li className="relative">
      <Link
        to={"/profile"}
        // ref/={trigger}
        className="relative flex h-8.5 w-8.5 items-center justify-center rounded-full border-[0.5px] border-stroke bg-gray hover:text-primary dark:border-strokedark dark:bg-meta-4 dark:text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          className="fill-current duration-300 ease-in-out"
          width="18"
          height="18"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
          />
        </svg>
      </Link>
    </li>
  );
};

export default DropdownProfile;
