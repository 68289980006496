import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import CountrySelector from "../../components/Selector";
import { COUNTRIES } from "../../constants";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { addNewClient } from "../../redux/actions/clientActions";
import InputModel from "../../components/InputModel";
import LayoutSection from "../../components/LayoutSection";

function AddClientScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  //
  const [firstName, setFirstName] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");

  const [city, setCity] = useState("");
  const [errorCity, setErrorCity] = useState("");
  const [country, setCountry] = useState("");
  const [errorCountry, setErrorCountry] = useState("");

  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState("");

  //
  const [isOpen, setIsOpen] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;

  const clientAdd = useSelector((state) => state.createNewClient);
  const { loadingClientAdd, errorClientAdd, successClientAdd } = clientAdd;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    }
  }, [navigate, userInfo, dispatch]);

  useEffect(() => {
    if (successClientAdd) {
      setFirstName("");
      setLastName("");
      setCity("");
      setCountry("");
      setEmail("");
      setPhone("");
    }
  }, [successClientAdd]);

  return (
    <DefaultLayout>
      <div>
        {/* top dash */}
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/clients/">
            <div className="">Clients</div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Nouveau</div>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Ajouter un nouveau client
            </h4>
          </div>
          {/*  */}
          <div className="flex md:flex-row flex-col ">
            <div className=" w-full px-1 py-1">
              <LayoutSection title="Informations personnelles">
                {/* fisrt name & last name */}

                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="First Name"
                    type="text"
                    placeholder=""
                    value={firstName}
                    onChange={(v) => setFirstName(v.target.value)}
                    error={errorFirstName}
                  />

                  <InputModel
                    label="Last Name"
                    type="text"
                    placeholder=""
                    value={lastName}
                    onChange={(v) => setLastName(v.target.value)}
                    error={errorLastName}
                  />
                </div>

                {/* date and nation */}
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Country"
                    type="select"
                    placeholder=""
                    value={country}
                    onChange={(v) => {
                      setCountry(v.target.value);
                    }}
                    error={errorCountry}
                    options={COUNTRIES?.map((country) => ({
                      value: country.value,
                      label: country.title,
                    }))}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="City"
                    type="text"
                    placeholder=""
                    value={city}
                    onChange={(v) => setCity(v.target.value)}
                    error={errorCity}
                  />
                </div>

                {/* phone and mail */}
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Phone"
                    type="text"
                    placeholder=""
                    value={phone}
                    onChange={(v) => setPhone(v.target.value)}
                    error={errorPhone}
                  />
                  <InputModel
                    label="Email"
                    type="email"
                    placeholder=""
                    value={email}
                    onChange={(v) => setEmail(v.target.value)}
                    error={errorEmail}
                  />
                </div>
              </LayoutSection>
            </div>
          </div>
          <div className="my-2 flex flex-row items-center justify-end">
            <button className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1">
              Annuler
            </button>
            <button
              onClick={async () => {
                var check = true;
                setErrorFirstName("");
                setErrorLastName("");
                setErrorCity("");
                setErrorCountry("");

                setErrorEmail("");
                setErrorPhone("");

                if (firstName === "") {
                  setErrorFirstName("Ce champ est requis.");
                  check = false;
                }
                if (lastName === "") {
                  setErrorLastName("Ce champ est requis.");
                  check = false;
                }
                if (city === "") {
                  setErrorCity("Ce champ est requis.");
                  check = false;
                }
                if (country === "") {
                  setErrorCountry("Ce champ est requis.");
                  check = false;
                }
                if (phone === "") {
                  setErrorPhone("Ce champ est requis.");
                  check = false;
                }
                if (email === "") {
                  setErrorEmail("Ce champ est requis.");
                  check = false;
                }

                if (check) {
                  setLoadEvent(true);
                  await dispatch(
                    addNewClient({
                      first_name: firstName,
                      last_name: lastName,
                      full_name: firstName + " " + lastName,
                      city: city,
                      country: country,
                      phone: phone,
                      email: email,
                    })
                  ).then(() => {});
                  setLoadEvent(false);
                } else {
                  toast.error(
                    "Certains champs sont obligatoires veuillez vérifier"
                  );
                }
              }}
              className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Ajouter
            </button>
          </div>
        </div>

        {/* buttom dash */}
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default AddClientScreen;
