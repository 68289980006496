import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />

    <Tooltip anchorSelect=".update-class" place="top">
      <button className="text-xs font-bold">Edit</button>
    </Tooltip>
    <Tooltip anchorSelect=".delete-class" place="top">
      <button className="text-xs font-bold">Delete</button>
    </Tooltip>

    <Tooltip anchorSelect=".history-class" place="top">
      <button className="text-xs font-bold">History</button>
    </Tooltip>
    <Tooltip anchorSelect=".detail-class" place="top">
      <button className="text-xs font-bold">Detail</button>
    </Tooltip>
    <Tooltip anchorSelect=".profile-class" place="top">
      <button className="text-xs font-bold">Profile</button>
    </Tooltip>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
