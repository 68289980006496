export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const USER_LOGOUT = "USER_LOGOUT";

export const USER_ADD_REQUEST = "USER_ADD_REQUEST";
export const USER_ADD_SUCCESS = "USER_ADD_SUCCESS";
export const USER_ADD_FAIL = "USER_ADD_FAIL";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";

export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAIL = "USER_PROFILE_FAIL";

export const USER_PROFILE_UPDATE_REQUEST = "USER_PROFILE_UPDATE_REQUEST";
export const USER_PROFILE_UPDATE_SUCCESS = "USER_PROFILE_UPDATE_SUCCESS";
export const USER_PROFILE_UPDATE_FAIL = "USER_PROFILE_UPDATE_FAIL";

export const USER_PASSWORD_UPDATE_REQUEST = "USER_PASSWORD_UPDATE_REQUEST";
export const USER_PASSWORD_UPDATE_SUCCESS = "USER_PASSWORD_UPDATE_SUCCESS";
export const USER_PASSWORD_UPDATE_FAIL = "USER_PASSWORD_UPDATE_FAIL";

export const COORDINATOR_LIST_REQUEST = "COORDINATOR_LIST_REQUEST";
export const COORDINATOR_LIST_SUCCESS = "COORDINATOR_LIST_SUCCESS";
export const COORDINATOR_LIST_FAIL = "COORDINATOR_LIST_FAIL";

export const COORDINATOR_ADD_REQUEST = "COORDINATOR_ADD_REQUEST";
export const COORDINATOR_ADD_SUCCESS = "COORDINATOR_ADD_SUCCESS";
export const COORDINATOR_ADD_FAIL = "COORDINATOR_ADD_FAIL";

export const COORDINATOR_DETAIL_REQUEST = "COORDINATOR_DETAIL_REQUEST";
export const COORDINATOR_DETAIL_SUCCESS = "COORDINATOR_DETAIL_SUCCESS";
export const COORDINATOR_DETAIL_FAIL = "COORDINATOR_DETAIL_FAIL";

export const COORDINATOR_UPDATE_REQUEST = "COORDINATOR_UPDATE_REQUEST";
export const COORDINATOR_UPDATE_SUCCESS = "COORDINATOR_UPDATE_SUCCESS";
export const COORDINATOR_UPDATE_FAIL = "COORDINATOR_UPDATE_FAIL";

export const USER_UPDATE_LOGIN_REQUEST = "USER_UPDATE_LOGIN_REQUEST";
export const USER_UPDATE_LOGIN_SUCCESS = "USER_UPDATE_LOGIN_SUCCESS";
export const USER_UPDATE_LOGIN_FAIL = "USER_UPDATE_LOGIN_FAIL";

export const USER_HISTORY_LOGED_REQUEST = "USER_HISTORY_LOGED_REQUEST";
export const USER_HISTORY_LOGED_SUCCESS = "USER_HISTORY_LOGED_SUCCESS";
export const USER_HISTORY_LOGED_FAIL = "USER_HISTORY_LOGED_FAIL";

export const USER_HISTORY_REQUEST = "USER_HISTORY_REQUEST";
export const USER_HISTORY_SUCCESS = "USER_HISTORY_SUCCESS";
export const USER_HISTORY_FAIL = "USER_HISTORY_FAIL";

export const USER_LOGOUT_SAVE_REQUEST = "USER_LOGOUT_SAVE_REQUEST";
export const USER_LOGOUT_SAVE_SUCCESS = "USER_LOGOUT_SAVE_SUCCESS";
export const USER_LOGOUT_SAVE_FAIL = "USER_LOGOUT_SAVE_FAIL";

export const USER_RESET_SEND_REQUEST = "USER_RESET_SEND_REQUEST";
export const USER_RESET_SEND_SUCCESS = "USER_RESET_SEND_SUCCESS";
export const USER_RESET_SEND_FAIL = "USER_RESET_SEND_FAIL";

export const USER_CONFIRM_RESET_SEND_REQUEST =
  "USER_CONFIRM_RESET_SEND_REQUEST";
export const USER_CONFIRM_RESET_SEND_SUCCESS =
  "USER_CONFIRM_RESET_SEND_SUCCESS";
export const USER_CONFIRM_RESET_SEND_FAIL = "USER_CONFIRM_RESET_SEND_FAIL";
