import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DefaultLayout from "../../layouts/DefaultLayout";
import Circle from "react-circle";

function KpisInformationScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // const listProviders = useSelector((state) => state.providerList);
  // const { providers, loadingProviders, errorProviders, pages } = listProviders;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      // dispatch(providersList(page));
    }
  }, [navigate, userInfo, dispatch]);

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">KPI´S / INFORMES</div>
        </div>
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default   dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black  text-xs w-max">
              KPI´S / INFORMES
            </h4>
          </div>
          {/*  */}
          <div className="my-5">
            <div className="flex md:flex-row flex-col">
              <div className="md:w-1/3 w-full p-3">
                <div className="flex flex-row items-center">
                  <div className="w-1/2 text-center">
                    % asistencias sin coordinar
                  </div>
                  <div className="w-1/2">
                    <Circle
                      animate={true} // Boolean: Animated/Static progress
                      animationDuration="1s" //String: Length of animation
                      responsive={true} // Boolean: Make SVG adapt to parent size
                      size={150} // Number: Defines the size of the circle.
                      lineWidth={14} // Number: Defines the thickness of the circle's stroke.
                      progress={69} // Number: Update to change the progress and percentage.
                      progressColor="cornflowerblue" // String: Color of "progress" portion of circle.
                      bgColor="whitesmoke" // String: Color of "empty" portion of circle.
                      textColor="hotpink" // String: Color of percentage text color.
                      textStyle={{
                        font: "bold 5rem Helvetica, Arial, sans-serif", // CSSProperties: Custom styling for percentage.
                      }}
                      percentSpacing={10} // Number: Adjust spacing of "%" symbol and number.
                      roundedStroke={true} // Boolean: Rounded/Flat line ends
                      showPercentage={true} // Boolean: Show/hide percentage.
                      showPercentageSymbol={true} // Boolean: Show/hide only the "%" symbol.
                    />
                  </div>
                </div>
              </div>
              <div className="md:w-1/3 w-full p-3">
                <div className="flex flex-row items-center">
                  <div className="w-1/2 text-center">
                    % asistencias / Sin coste
                  </div>
                  <div className="w-1/2">
                    <Circle
                      animate={true} // Boolean: Animated/Static progress
                      animationDuration="1s" //String: Length of animation
                      responsive={true} // Boolean: Make SVG adapt to parent size
                      size={150} // Number: Defines the size of the circle.
                      lineWidth={14} // Number: Defines the thickness of the circle's stroke.
                      progress={69} // Number: Update to change the progress and percentage.
                      progressColor="cornflowerblue" // String: Color of "progress" portion of circle.
                      bgColor="whitesmoke" // String: Color of "empty" portion of circle.
                      textColor="hotpink" // String: Color of percentage text color.
                      textStyle={{
                        font: "bold 5rem Helvetica, Arial, sans-serif", // CSSProperties: Custom styling for percentage.
                      }}
                      percentSpacing={10} // Number: Adjust spacing of "%" symbol and number.
                      roundedStroke={true} // Boolean: Rounded/Flat line ends
                      showPercentage={true} // Boolean: Show/hide percentage.
                      showPercentageSymbol={true} // Boolean: Show/hide only the "%" symbol.
                    />
                  </div>
                </div>
              </div>
              <div className="md:w-1/3 w-full p-3">
                <div className="flex flex-row items-center">
                  <div className="w-1/2 text-center">
                    % asistencias / Sin IM
                  </div>
                  <div className="w-1/2">
                    <Circle
                      animate={true} // Boolean: Animated/Static progress
                      animationDuration="1s" //String: Length of animation
                      responsive={true} // Boolean: Make SVG adapt to parent size
                      size={150} // Number: Defines the size of the circle.
                      lineWidth={14} // Number: Defines the thickness of the circle's stroke.
                      progress={69} // Number: Update to change the progress and percentage.
                      progressColor="cornflowerblue" // String: Color of "progress" portion of circle.
                      bgColor="whitesmoke" // String: Color of "empty" portion of circle.
                      textColor="hotpink" // String: Color of percentage text color.
                      textStyle={{
                        font: "bold 5rem Helvetica, Arial, sans-serif", // CSSProperties: Custom styling for percentage.
                      }}
                      percentSpacing={10} // Number: Adjust spacing of "%" symbol and number.
                      roundedStroke={true} // Boolean: Rounded/Flat line ends
                      showPercentage={true} // Boolean: Show/hide percentage.
                      showPercentageSymbol={true} // Boolean: Show/hide only the "%" symbol.
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex md:flex-row flex-col">
              <div className="md:w-1/3 w-full p-3">
                <div className="flex flex-row items-center">
                  <div className="w-1/2 text-center">% casos / cliente</div>
                  <div className="w-1/2">
                    <Circle
                      animate={true} // Boolean: Animated/Static progress
                      animationDuration="1s" //String: Length of animation
                      responsive={true} // Boolean: Make SVG adapt to parent size
                      size={150} // Number: Defines the size of the circle.
                      lineWidth={14} // Number: Defines the thickness of the circle's stroke.
                      progress={69} // Number: Update to change the progress and percentage.
                      progressColor="cornflowerblue" // String: Color of "progress" portion of circle.
                      bgColor="whitesmoke" // String: Color of "empty" portion of circle.
                      textColor="hotpink" // String: Color of percentage text color.
                      textStyle={{
                        font: "bold 5rem Helvetica, Arial, sans-serif", // CSSProperties: Custom styling for percentage.
                      }}
                      percentSpacing={10} // Number: Adjust spacing of "%" symbol and number.
                      roundedStroke={true} // Boolean: Rounded/Flat line ends
                      showPercentage={true} // Boolean: Show/hide percentage.
                      showPercentageSymbol={true} // Boolean: Show/hide only the "%" symbol.
                    />
                  </div>
                </div>
              </div>
              <div className="md:w-1/3 w-full p-3">
                <div className="flex flex-row items-center">
                  <div className="w-1/2 text-center">
                    % casos / tipo de asistencia
                  </div>
                  <div className="w-1/2">
                    <Circle
                      animate={true} // Boolean: Animated/Static progress
                      animationDuration="1s" //String: Length of animation
                      responsive={true} // Boolean: Make SVG adapt to parent size
                      size={150} // Number: Defines the size of the circle.
                      lineWidth={14} // Number: Defines the thickness of the circle's stroke.
                      progress={69} // Number: Update to change the progress and percentage.
                      progressColor="cornflowerblue" // String: Color of "progress" portion of circle.
                      bgColor="whitesmoke" // String: Color of "empty" portion of circle.
                      textColor="hotpink" // String: Color of percentage text color.
                      textStyle={{
                        font: "bold 5rem Helvetica, Arial, sans-serif", // CSSProperties: Custom styling for percentage.
                      }}
                      percentSpacing={10} // Number: Adjust spacing of "%" symbol and number.
                      roundedStroke={true} // Boolean: Rounded/Flat line ends
                      showPercentage={true} // Boolean: Show/hide percentage.
                      showPercentageSymbol={true} // Boolean: Show/hide only the "%" symbol.
                    />
                  </div>
                </div>
              </div>
              <div className="md:w-1/3 w-full p-3">
                <div className="flex flex-row items-center">
                  <div className="w-1/2 text-center">% casos/ pais</div>
                  <div className="w-1/2">
                    <Circle
                      animate={true} // Boolean: Animated/Static progress
                      animationDuration="1s" //String: Length of animation
                      responsive={true} // Boolean: Make SVG adapt to parent size
                      size={150} // Number: Defines the size of the circle.
                      lineWidth={14} // Number: Defines the thickness of the circle's stroke.
                      progress={69} // Number: Update to change the progress and percentage.
                      progressColor="cornflowerblue" // String: Color of "progress" portion of circle.
                      bgColor="whitesmoke" // String: Color of "empty" portion of circle.
                      textColor="hotpink" // String: Color of percentage text color.
                      textStyle={{
                        font: "bold 5rem Helvetica, Arial, sans-serif", // CSSProperties: Custom styling for percentage.
                      }}
                      percentSpacing={10} // Number: Adjust spacing of "%" symbol and number.
                      roundedStroke={true} // Boolean: Rounded/Flat line ends
                      showPercentage={true} // Boolean: Show/hide percentage.
                      showPercentageSymbol={true} // Boolean: Show/hide only the "%" symbol.
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default KpisInformationScreen;
