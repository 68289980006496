import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { casesList, deleteCase } from "../../redux/actions/caseActions";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import Paginate from "../../components/Paginate";
import DefaultLayout from "../../layouts/DefaultLayout";
import ConfirmationModal from "../../components/ConfirmationModal";

function CaseScreen() {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const dispatch = useDispatch();

  const [isDelete, setIsDelete] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  const [eventType, setEventType] = useState("");
  const [caseId, setCaseId] = useState("");

  const [filterPaid, setFilterPaid] = useState("");

  const [filterSelect, setFilterSelect] = useState([]);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const listCases = useSelector((state) => state.caseList);
  const { cases, loadingCases, errorCases, pages } = listCases;

  const caseDelete = useSelector((state) => state.deleteCase);
  const { loadingCaseDelete, errorCaseDelete, successCaseDelete } = caseDelete;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(casesList(page));
    }
  }, [navigate, userInfo, dispatch, page]);

  useEffect(() => {
    if (successCaseDelete) {
      const queryString = filterSelect
        .map((status) => encodeURIComponent(status))
        .join(",");
      dispatch(casesList("1", queryString));
    }
  }, [successCaseDelete]);

  const formatDate = (dateString) => {
    if (dateString && dateString !== "") {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    } else {
      return dateString;
    }
  };

  const handleCheckboxChange = (value) => {
    setFilterSelect((prevState) => {
      let updatedFilterSelect;

      if (prevState.includes(value)) {
        // Remove it if it exists
        updatedFilterSelect = prevState.filter((item) => item !== value);
      } else {
        // Add it if it doesn't exist
        updatedFilterSelect = [...prevState, value];
      }

      // Now that the state is updated, build the queryString using the updated value
      const queryString = updatedFilterSelect
        .map((status) => encodeURIComponent(status))
        .join(",");

      // Dispatch action with the correct queryString
      dispatch(casesList("1", queryString));

      // Return the updated state
      return updatedFilterSelect;
    });
  };

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Cases list</div>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-2 pt-6 pb-2.5 shadow-default   dark:bg-boxdark ">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black  text-xs w-max">
              Cases list
            </h4>
            {pathname.includes("/cases") && !pathname.includes("cases-list") ? (
              <a
                href="/cases/new"
                className="bg-primary text-white text-sm px-5 py-3 rounded-full"
              >
                Add new case
              </a>
            ) : null}
          </div>
          <div className="flex md:flex-row flex-col ">
            <div className="md:w-1/3 w-full px-1 py-3 ">
              <div className="rounded border border-[#BEBEBE] shadow-1 py-4 px-2">
                <div className="flex flex-row text-xs items-center my-3 text-danger">
                  <input
                    onChange={(v) => {
                      handleCheckboxChange("pending-coordination");
                    }}
                    id="pending-coordination"
                    type={"checkbox"}
                    checked={filterSelect.includes("pending-coordination")}
                    className="mx-1"
                  />
                  <label
                    for="pending-coordination"
                    className="flex-1 mx-1  cursor-pointer "
                  >
                    Pending Coordination
                  </label>
                </div>
                <div className="flex flex-row text-xs items-center my-3 text-[#FFA500]">
                  <input
                    onChange={(v) => {
                      handleCheckboxChange("coordinated-missing-m-r");
                    }}
                    checked={filterSelect.includes("coordinated-missing-m-r")}
                    id="coordinated-Missing-m-r"
                    type={"checkbox"}
                    className="mx-1"
                  />
                  <label
                    for="coordinated-Missing-m-r"
                    className="flex-1 mx-1  cursor-pointer "
                  >
                    Coordinated, Missing M.R.
                  </label>
                </div>
                <div className="flex flex-row text-xs items-center my-3 text-[#FFA500]">
                  <input
                    onChange={(v) => {
                      handleCheckboxChange("coordinated-missing-invoice");
                    }}
                    checked={filterSelect.includes(
                      "coordinated-missing-invoice"
                    )}
                    id="coordinated-missing-invoice"
                    type={"checkbox"}
                    className="mx-1"
                  />
                  <label
                    for="coordinated-missing-invoice"
                    className="flex-1 mx-1  cursor-pointer "
                  >
                    Coordinated, Missing Invoice
                  </label>
                </div>
                <div className="flex flex-row text-xs items-center my-3 text-primary">
                  <input
                    onChange={(v) => {
                      handleCheckboxChange(
                        "waiting-for-insurance-authorization"
                      );
                    }}
                    checked={filterSelect.includes(
                      "waiting-for-insurance-authorization"
                    )}
                    id="waiting-for-insurance-authorization"
                    type={"checkbox"}
                    className="mx-1"
                  />
                  <label
                    for="waiting-for-insurance-authorization"
                    className="flex-1 mx-1  cursor-pointer "
                  >
                    Waiting for Insurance Authorization
                  </label>
                </div>
                <div className="flex flex-row text-xs items-center my-3 text-primary">
                  <input
                    onChange={(v) => {
                      handleCheckboxChange("coordinated-patient-not-seen-yet");
                    }}
                    checked={filterSelect.includes(
                      "coordinated-patient-not-seen-yet"
                    )}
                    id="coordinated-patient-not-seen-yet"
                    type={"checkbox"}
                    className="mx-1"
                  />
                  <label
                    for="coordinated-patient-not-seen-yet"
                    className="flex-1 mx-1  cursor-pointer "
                  >
                    Coordinated, Patient not seen yet
                  </label>
                </div>
                <div className="flex flex-row text-xs items-center my-3 text-[#008000]">
                  <input
                    onChange={(v) => {
                      handleCheckboxChange("fully-coordinated");
                    }}
                    checked={filterSelect.includes("fully-coordinated")}
                    id="fully-coordinated"
                    type={"checkbox"}
                    className="mx-1"
                  />
                  <label
                    for="fully-coordinated"
                    className="flex-1 mx-1  cursor-pointer "
                  >
                    Fully Coordinated
                  </label>
                </div>
                <div className="flex flex-row text-xs items-center my-3 text-[#d34053]">
                  <input
                    onChange={(v) => {
                      handleCheckboxChange("failed");
                    }}
                    checked={filterSelect.includes("failed")}
                    id="failed"
                    type={"checkbox"}
                    className="mx-1"
                  />
                  <label for="failed" className="flex-1 mx-1  cursor-pointer ">
                    Failed
                  </label>
                </div>
                <div className="flex flex-row text-xs items-center my-3 text-black">
                  <input
                    onChange={(v) => {
                      if (filterPaid === "paid") {
                        setFilterPaid("");
                        const queryString = filterSelect
                          .map((status) => encodeURIComponent(status))
                          .join(",");

                        // Dispatch action with the correct queryString
                        dispatch(
                          casesList(
                            "1",
                            queryString,
                            "",
                            "",
                            "",
                            "",
                            "",
                            "",
                            "",
                            "",
                            ""
                          )
                        );
                      } else {
                        setFilterPaid("paid");
                        const queryString = filterSelect
                          .map((status) => encodeURIComponent(status))
                          .join(",");

                        // Dispatch action with the correct queryString
                        dispatch(
                          casesList(
                            "1",
                            queryString,
                            "",
                            "",
                            "",
                            "",
                            "",
                            "",
                            "",
                            "",
                            "paid"
                          )
                        );
                      }
                    }}
                    checked={filterPaid === "paid"}
                    id="paidfilter"
                    type={"checkbox"}
                    className="mx-1"
                  />
                  <label
                    for="paidfilter"
                    className="flex-1 mx-1  cursor-pointer text-success"
                  >
                    Paid
                  </label>
                </div>

                <div className="flex flex-row text-xs items-center my-3 text-black">
                  <input
                    onChange={(v) => {
                      if (filterPaid === "unpaid") {
                        setFilterPaid("");
                        setFilterPaid("");
                        const queryString = filterSelect
                          .map((status) => encodeURIComponent(status))
                          .join(",");

                        // Dispatch action with the correct queryString
                        dispatch(
                          casesList(
                            "1",
                            queryString,
                            "",
                            "",
                            "",
                            "",
                            "",
                            "",
                            "",
                            "",
                            ""
                          )
                        );
                      } else {
                        setFilterPaid("unpaid");

                        const queryString = filterSelect
                          .map((status) => encodeURIComponent(status))
                          .join(",");

                        // Dispatch action with the correct queryString
                        dispatch(
                          casesList(
                            "1",
                            queryString,
                            "",
                            "",
                            "",
                            "",
                            "",
                            "",
                            "",
                            "",
                            "unpaid"
                          )
                        );
                      }
                    }}
                    checked={filterPaid === "unpaid"}
                    id="unpaidfilter"
                    type={"checkbox"}
                    className="mx-1"
                  />
                  <label
                    for="unpaidfilter"
                    className="flex-1 mx-1  cursor-pointer text-danger "
                  >
                    Unpaid
                  </label>
                </div>
              </div>
            </div>
            <div className="md:w-2/3 w-full  px-1 py-3 ">
              <div className="py-4 px-2 shadow-1 bg-white">
                {loadingCases ? (
                  <Loader />
                ) : errorCases ? (
                  <Alert type="error" message={errorCases} />
                ) : (
                  <div className="max-w-full overflow-x-auto ">
                    <table className="w-full table-auto">
                      <thead>
                        <tr className=" bg-[#F3F5FB] text-left ">
                          <th className="min-w-[60px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max ">
                            Creation date
                          </th>
                          <th className="min-w-[60px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max ">
                            Client
                          </th>
                          <th className="min-w-[30px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                            Case ID
                          </th>
                          <th className="min-w-[120px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                            Pax
                          </th>
                          <th className="min-w-[120px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                            City/Country
                          </th>

                          <th className="py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                            Operation
                          </th>
                        </tr>
                      </thead>
                      {/*  */}
                      <tbody>
                        {cases?.map((item, index) => (
                          //  <a href={`/cases/detail/${item.id}`}></a>
                          <tr key={index}>
                            <td className=" py-3 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max  ">
                                {formatDate(item.case_date)}
                              </p>
                            </td>
                            <td className=" py-3 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max  ">
                                {item.assurance?.assurance_name ?? "---"}
                              </p>
                            </td>
                            <td className=" py-3 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max  ">
                                #{item.id}
                              </p>
                            </td>
                            <td className=" py-3 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max  ">
                                {item.patient?.full_name ?? "---"}
                              </p>
                            </td>

                            <td className=" py-3 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max  ">
                                {item.patient?.patient_country ?? ""}
                                {" / "}
                                {item.patient?.patient_city ?? ""}
                                {/* {item.provider?.city ?? ""} /{" "}
                                {item.provider?.country ?? ""} */}
                              </p>
                            </td>

                            <td className=" py-3 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max flex flex-row  ">
                                <Link
                                  className="mx-1 detail-class"
                                  to={"/cases-list/detail/" + item.id}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 bg-warning rounded p-1 text-white text-center text-xs"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                                    />
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                    />
                                  </svg>
                                </Link>
                                <Link
                                  className="mx-1 update-class"
                                  to={"/cases/edit/" + item.id}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 bg-primary rounded p-1 text-white text-center text-xs"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                    />
                                  </svg>
                                </Link>
                                <div
                                  onClick={() => {
                                    setEventType("delete");
                                    setCaseId(item.id);
                                    setIsDelete(true);
                                  }}
                                  className="mx-1 delete-class cursor-pointer"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                                    />
                                  </svg>
                                </div>
                              </p>
                            </td>
                          </tr>
                        ))}
                        <tr className="h-11"></tr>
                      </tbody>
                    </table>
                  </div>
                )}
                <div className="">
                  <Paginate
                    route={"/cases-list?"}
                    search={""}
                    page={page}
                    pages={pages}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmationModal
          isOpen={isDelete}
          message={
            eventType === "delete"
              ? "Are you sure you want to delete this case?"
              : "Are you sure ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setIsDelete(false);
              setEventType("");
              setLoadEvent(false);
            } else if (eventType === "delete" && caseId !== "") {
              setLoadEvent(true);
              dispatch(deleteCase(caseId));
              setIsDelete(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setIsDelete(false);
              setEventType("");
              setLoadEvent(false);
            }
          }}
          onCancel={() => {
            setIsDelete(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default CaseScreen;
