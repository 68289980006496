import axios from "../../axios";
import {
  INSURANCE_LIST_REQUEST,
  INSURANCE_LIST_SUCCESS,
  INSURANCE_LIST_FAIL,
  //
  INSURANCE_ADD_REQUEST,
  INSURANCE_ADD_SUCCESS,
  INSURANCE_ADD_FAIL,
  //
  INSURANCE_DETAIL_REQUEST,
  INSURANCE_DETAIL_SUCCESS,
  INSURANCE_DETAIL_FAIL,
  //
  INSURANCE_UPDATE_REQUEST,
  INSURANCE_UPDATE_SUCCESS,
  INSURANCE_UPDATE_FAIL,
  //
  INSURANCE_DELETE_REQUEST,
  INSURANCE_DELETE_SUCCESS,
  INSURANCE_DELETE_FAIL,
  //
} from "../constants/insuranceConstants";

export const updateInsurance =
  (id, insurance) => async (dispatch, getState) => {
    try {
      dispatch({
        type: INSURANCE_UPDATE_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.put(
        `/insurances/update/${id}/`,
        insurance,
        config
      );

      dispatch({
        type: INSURANCE_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoUnimedCare");
          document.location.href = "/";
        }
      }
      dispatch({
        type: INSURANCE_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

export const detailInsurance = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: INSURANCE_DETAIL_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/insurances/detail/${id}/`, config);

    dispatch({
      type: INSURANCE_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoUnimedCare");
        document.location.href = "/";
      }
    }
    dispatch({
      type: INSURANCE_DETAIL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// delete Insurance
export const deleteInsurance = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: INSURANCE_DELETE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.delete(`/insurances/delete/${id}/`, config);

    dispatch({
      type: INSURANCE_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoUnimedCare");
        document.location.href = "/";
      }
    }
    dispatch({
      type: INSURANCE_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// create insurance
export const createNewInsurance = (insurance) => async (dispatch, getState) => {
  try {
    dispatch({
      type: INSURANCE_ADD_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.post(
      `/insurances/create-new-insurance/`,
      insurance,
      config
    );

    dispatch({
      type: INSURANCE_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoUnimedCare");
        document.location.href = "/";
      }
    }
    dispatch({
      type: INSURANCE_ADD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

export const getInsuranesList = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: INSURANCE_LIST_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/insurances/?page=${page}`, config);

    dispatch({
      type: INSURANCE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoUnimedCare");
        document.location.href = "/";
      }
    }
    dispatch({
      type: INSURANCE_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};
