import React, { useEffect, useState } from "react";
import {
  createNewCoordinator,
  getCoordinatorDetail,
  updateCoordinator,
} from "../../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "../../layouts/DefaultLayout";
import { toast } from "react-toastify";

function EditCoordinatorScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  let { id } = useParams();

  const [isOpen, setIsOpen] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);

  const [coordinatorFirstName, setCoordinatorFirstName] = useState("");
  const [coordinatorFirstNameError, setCoordinatorFirstNameError] =
    useState("");

  const [coordinatorLastName, setCoordinatorLastName] = useState("");
  const [coordinatorLastNameError, setCoordinatorLastNameError] = useState("");

  const [coordinatorEmail, setCoordinatorEmail] = useState("");
  const [coordinatorEmailError, setCoordinatorEmailError] = useState("");

  const [coordinatorPhone, setCoordinatorPhone] = useState("");
  const [coordinatorPhoneError, setCoordinatorPhoneError] = useState("");

  const [coordinatorPassword, setCoordinatorPassword] = useState("");
  const [coordinatorPasswordError, setCoordinatorPasswordError] = useState("");

  const [coordinatorConfirmPassword, setCoordinatorConfirmPassword] =
    useState("");
  const [coordinatorConfirmPasswordError, setCoordinatorConfirmPasswordError] =
    useState("");

  const [coordinatorLogo, setCoordinatorLogo] = useState("");
  const [coordinatorLogoValue, setCoordinatorLogoValue] = useState("");
  const [coordinatorLogoError, setCoordinatorLogoError] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const coordinatorDetail = useSelector((state) => state.detailCoordinator);
  const {
    loadingCoordinatorInfo,
    errorCoordinatorInfo,
    successCoordinatorInfo,
    coordinatorInfo,
  } = coordinatorDetail;

  const coordinatorUpdate = useSelector((state) => state.updateCoordinator);
  const {
    loadingCoordinatorUpdate,
    errorCoordinatorUpdate,
    successCoordinatorUpdate,
  } = coordinatorUpdate;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else if (
      userInfo.role !== "1" &&
      userInfo.role !== 1 &&
      userInfo.role !== "2" &&
      userInfo.role !== 2
    ) {
      navigate(redirect);
    } else {
      dispatch(getCoordinatorDetail(id));
    }
  }, [navigate, userInfo, dispatch, id]);

  useEffect(() => {
    if (
      coordinatorInfo &&
      coordinatorInfo !== undefined &&
      coordinatorInfo !== null
    ) {
      setCoordinatorFirstName(coordinatorInfo.first_name);
      setCoordinatorLastName(coordinatorInfo.last_name);
      setCoordinatorEmail(coordinatorInfo.email);
      setCoordinatorPhone(coordinatorInfo.phone);
    }
  }, [coordinatorInfo]);

  useEffect(() => {
    if (successCoordinatorUpdate) {
      setCoordinatorFirstName("");
      setCoordinatorFirstNameError("");
      setCoordinatorLastName("");
      setCoordinatorLastNameError("");
      setCoordinatorPassword("");
      setCoordinatorPasswordError("");
      setCoordinatorConfirmPassword("");
      setCoordinatorConfirmPasswordError("");
      setCoordinatorEmail("");
      setCoordinatorEmailError("");
      setCoordinatorPhone("");
      setCoordinatorPhoneError("");
      setCoordinatorLogo("");
      setCoordinatorLogoError("");
      setCoordinatorLogoValue("");
      dispatch(getCoordinatorDetail(id));
    }
  }, [successCoordinatorUpdate]);

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
          <a href="/coordinator-space">
            <div className="flex flex-row  items-center hover:text-black ">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m8.25 4.5 7.5 7.5-7.5 7.5"
                  />
                </svg>
              </span>
              <div className="">Coordinator Space</div>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Edit Coordinator</div>
        </div>
        {/*  */}
        <div className="py-5 px-4 flex justify-between">
          <h4 className=" uppercase font-semibold text-black dark:text-white">
            Edit Coordinator
          </h4>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="my-2 bg-white py-4 px-2 rounded-md">
            <div className="flex md:flex-row flex-col  ">
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  First Name <strong className="text-danger">*</strong>
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      coordinatorFirstNameError
                        ? "border-danger"
                        : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="text"
                    placeholder="First Name"
                    value={coordinatorFirstName}
                    onChange={(v) => setCoordinatorFirstName(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger">
                    {coordinatorFirstNameError ? coordinatorFirstNameError : ""}
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Last Name
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      coordinatorLastNameError
                        ? "border-danger"
                        : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="text"
                    placeholder="Last Name"
                    value={coordinatorLastName}
                    onChange={(v) => setCoordinatorLastName(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger">
                    {coordinatorLastNameError ? coordinatorLastNameError : ""}
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="flex md:flex-row flex-col  ">
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Coordinator Email <strong className="text-danger">*</strong>
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      coordinatorEmailError
                        ? "border-danger"
                        : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="email"
                    placeholder="Coordinator Email"
                    value={coordinatorEmail}
                    onChange={(v) => setCoordinatorEmail(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger">
                    {coordinatorEmailError ? coordinatorEmailError : ""}
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Coordinator Phone <strong className="text-danger">*</strong>
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      coordinatorPhoneError
                        ? "border-danger"
                        : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="text"
                    placeholder="Coordinator Phone"
                    value={coordinatorPhone}
                    onChange={(v) => setCoordinatorPhone(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger">
                    {coordinatorPhoneError ? coordinatorPhoneError : ""}
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="flex md:flex-row flex-col  ">
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Coordinator Password
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      coordinatorPasswordError
                        ? "border-danger"
                        : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="password"
                    placeholder="Coordinator Password"
                    value={coordinatorPassword}
                    onChange={(v) => setCoordinatorPassword(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger">
                    {coordinatorPasswordError ? coordinatorPasswordError : ""}
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Confirm Password
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      coordinatorConfirmPasswordError
                        ? "border-danger"
                        : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="password"
                    placeholder="Confirm Password"
                    value={coordinatorConfirmPassword}
                    onChange={(v) =>
                      setCoordinatorConfirmPassword(v.target.value)
                    }
                  />
                  <div className=" text-[8px] text-danger">
                    {coordinatorConfirmPasswordError
                      ? coordinatorConfirmPasswordError
                      : ""}
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="flex md:flex-row flex-col  ">
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Coordinator Image
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      coordinatorLogoError
                        ? "border-danger"
                        : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="file"
                    placeholder="Coordinator Image"
                    value={coordinatorLogoValue}
                    onChange={(v) => {
                      setCoordinatorLogo(v.target.files[0]);
                      setCoordinatorLogoValue(v.target.value);
                    }}
                  />
                  <div className=" text-[8px] text-danger">
                    {coordinatorLogoError ? coordinatorLogoError : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="my-3 ">
              <div className="flex flex-row items-center justify-end my-3">
                <a
                  href="/coordinator-space"
                  className="bg-white text-[#0388A6] text-sm px-5 py-3 rounded-full mr-3"
                >
                  Back
                </a>
                <button
                  onClick={async () => {
                    var check = true;
                    setCoordinatorFirstNameError("");
                    setCoordinatorLastNameError("");
                    setCoordinatorEmailError("");
                    setCoordinatorPhoneError("");
                    setCoordinatorConfirmPasswordError("");
                    setCoordinatorPasswordError("");
                    setCoordinatorLogoError("");

                    if (coordinatorFirstName === "") {
                      setCoordinatorFirstNameError(
                        "These fields are required."
                      );
                      check = false;
                    }
                    if (coordinatorEmail === "") {
                      setCoordinatorEmailError("These fields are required.");
                      check = false;
                    }
                    if (coordinatorPhone === "") {
                      setCoordinatorPhoneError("These fields are required.");
                      check = false;
                    }
                    if (
                      coordinatorPassword !== "" &&
                      coordinatorPassword === ""
                    ) {
                      setCoordinatorConfirmPasswordError(
                        "These fields are required."
                      );
                      check = false;
                    }
                    if (
                      coordinatorPassword !== "" &&
                      coordinatorPassword !== coordinatorConfirmPassword
                    ) {
                      setCoordinatorConfirmPasswordError(
                        "Please confirm password"
                      );
                      check = false;
                    }

                    if (check) {
                      setLoadEvent(true);
                      await dispatch(
                        updateCoordinator(id, {
                          first_name: coordinatorFirstName,
                          last_name: coordinatorLastName,
                          full_name:
                            coordinatorFirstName + " " + coordinatorLastName,
                          email: coordinatorEmail,
                          phone: coordinatorPhone,
                          password: coordinatorPassword,
                          coordinator_image: coordinatorLogo,
                        })
                      ).then(() => {});
                      setLoadEvent(false);
                    } else {
                      toast.error(
                        "Some fields are empty or invalid. please try again"
                      );
                    }
                  }}
                  className="text-white bg-[#0388A6] text-sm px-5 py-3 rounded-full"
                >
                  {loadEvent ? "Loading ..." : "Update"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default EditCoordinatorScreen;
