import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getUserProfile,
  updateUserPassword,
  updateUserProfile,
} from "../../redux/actions/userActions";
import Alert from "../../components/Alert";
import { baseURLFile } from "../../constants";

function SettingsScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [coordinatorLogo, setCoordinatorLogo] = useState("");
  const [coordinatorLogoValue, setCoordinatorLogoValue] = useState("");
  const [coordinatorLogoError, setCoordinatorLogoError] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [loadEvent, setLoadEvent] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const profileUser = useSelector((state) => state.getProfileUser);
  const {
    loadingUserProfile,
    userProfile,
    successUserProfile,
    errorUserProfile,
  } = profileUser;

  const profileUserUpdate = useSelector((state) => state.updateProfileUser);
  const {
    loadingUserProfileUpdate,
    successUserProfileUpdate,
    errorUserProfileUpdate,
  } = profileUserUpdate;

  const passwordUserUpdate = useSelector((state) => state.updatePasswordUser);
  const {
    loadingUserPasswordUpdate,
    successUserPasswordUpdate,
    errorUserPasswordUpdate,
  } = passwordUserUpdate;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getUserProfile());
    }
  }, [navigate, userInfo, dispatch]);

  useEffect(() => {
    if (successUserProfile) {
      if (userProfile && userProfile !== null && userProfile !== undefined) {
        setEmail(userProfile.email ?? "");
        setFirstName(userProfile.first_name ?? "");
        setLastName(userProfile.last_name ?? "");
        setPhone(userProfile.phone ?? "");
      }
    }
  }, [successUserProfile]);

  useEffect(() => {
    if (successUserProfileUpdate) {
      dispatch(getUserProfile());
    }
  }, [successUserProfileUpdate]);

  useEffect(() => {
    if (successUserPasswordUpdate) {
      dispatch(getUserProfile());
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setOldPasswordError("");
      setNewPasswordError("");
      setConfirmPasswordError("");
    }
  }, [successUserPasswordUpdate]);

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Settings</div>
        </div>
        {/*  */}
        <div className="py-5 px-4 flex justify-between">
          <h4 className=" uppercase font-semibold text-black dark:text-white">
            Update Profile
          </h4>
        </div>
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div>
            {errorUserProfile ? (
              <Alert type={"error"} message={errorUserProfile} />
            ) : errorUserProfileUpdate ? (
              <Alert type={"error"} message={errorUserProfileUpdate} />
            ) : null}
          </div>
          <div className="my-2 bg-white py-4 px-2 rounded-md">
            {userProfile ? (
              <div className="flex md:flex-row flex-col  mb-2 ">
                {userProfile.photo ? (
                  <a href={baseURLFile + userProfile.photo}>
                    <img
                      src={baseURLFile + userProfile.photo}
                      className="size-24"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "/assets/placeholder.png";
                      }}
                    />
                  </a>
                ) : (
                  <img
                    src={"/assets/placeholder.png"}
                    className="size-20"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "/assets/placeholder.png";
                    }}
                  />
                )}
              </div>
            ) : null}
            <div className="flex md:flex-row flex-col  ">
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  First Name <strong className="text-danger">*</strong>
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      firstNameError ? "border-danger" : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(v) => setFirstName(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger">
                    {firstNameError ? firstNameError : ""}
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Last Name
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      lastNameError ? "border-danger" : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(v) => setLastName(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger">
                    {lastNameError ? lastNameError : ""}
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="flex md:flex-row flex-col  ">
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Email <strong className="text-danger">*</strong>
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      emailError ? "border-danger" : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(v) => setEmail(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger">
                    {emailError ? emailError : ""}
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Phone <strong className="text-danger">*</strong>
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      phoneError ? "border-danger" : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="text"
                    placeholder="Phone"
                    value={phone}
                    onChange={(v) => setPhone(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger">
                    {phoneError ? phoneError : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex md:flex-row flex-col  ">
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Image
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      coordinatorLogoError
                        ? "border-danger"
                        : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="file"
                    accept="image/*"
                    placeholder=" Image"
                    value={coordinatorLogoValue}
                    onChange={(v) => {
                      setCoordinatorLogo(v.target.files[0]);
                      setCoordinatorLogoValue(v.target.value);
                    }}
                  />
                  <div className=" text-[8px] text-danger">
                    {coordinatorLogoError ? coordinatorLogoError : ""}
                  </div>
                </div>
              </div>
            </div>
            {/*  */}

            <div className="my-3 ">
              <div className="flex flex-row items-center justify-end my-3">
                <a
                  href="/dashboard"
                  className="bg-white text-[#0388A6] text-sm px-5 py-3 rounded-full mr-3"
                >
                  Cancel
                </a>
                <button
                  onClick={async () => {
                    var check = true;
                    setFirstNameError("");
                    setLastNameError("");
                    setEmailError("");
                    setPhoneError("");

                    if (firstName === "") {
                      setFirstNameError("These fields are required.");
                      check = false;
                    }
                    if (email === "") {
                      setEmailError("These fields are required.");
                      check = false;
                    }
                    if (phone === "") {
                      setPhoneError("These fields are required.");
                      check = false;
                    }

                    if (check) {
                      setLoadEvent(true);
                      await dispatch(
                        updateUserProfile({
                          first_name: firstName,
                          last_name: lastName,
                          full_name: firstName + " " + lastName,
                          email: email,
                          phone: phone,
                          coordinator_image: coordinatorLogo,
                        })
                      ).then(() => {});
                      setLoadEvent(false);
                    } else {
                      toast.error(
                        "Some fields are empty or invalid. please try again"
                      );
                    }
                  }}
                  className="text-white bg-[#0388A6] text-sm px-5 py-3 rounded-full"
                >
                  {1 == 2 ? "Loading ..." : "Update Profile"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="py-5 px-4 flex justify-between">
          <h4 className=" uppercase font-semibold text-black dark:text-white">
            Update Passowrd
          </h4>
        </div>
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div>
            {errorUserPasswordUpdate ? (
              <Alert type={"error"} message={errorUserPasswordUpdate} />
            ) : null}
          </div>
          <div className="my-2 bg-white py-4 px-2 rounded-md">
            <div className="flex md:flex-row flex-col  ">
              <div className=" w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Old Password <strong className="text-danger">*</strong>
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      oldPasswordError ? "border-danger" : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="password"
                    placeholder="Old Password"
                    value={oldPassword}
                    onChange={(v) => setOldPassword(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger">
                    {oldPasswordError ? oldPasswordError : ""}
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="flex md:flex-row flex-col  ">
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  New Password <strong className="text-danger">*</strong>
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      newPasswordError ? "border-danger" : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="password"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(v) => setNewPassword(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger">
                    {newPasswordError ? newPasswordError : ""}
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Confirm Password <strong className="text-danger">*</strong>
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      confirmPasswordError
                        ? "border-danger"
                        : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(v) => setConfirmPassword(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger">
                    {confirmPasswordError ? confirmPasswordError : ""}
                  </div>
                </div>
              </div>
            </div>
            {/*  */}

            <div className="my-3 ">
              <div className="flex flex-row items-center justify-end my-3">
                <a
                  href="/dashboard"
                  className="bg-white text-[#0388A6] text-sm px-5 py-3 rounded-full mr-3"
                >
                  Cancel
                </a>
                <button
                  onClick={async () => {
                    var check = true;
                    setOldPasswordError("");
                    setNewPasswordError("");
                    setConfirmPasswordError("");

                    if (oldPassword === "") {
                      setOldPasswordError("These fields are required.");
                      check = false;
                    }
                    if (newPassword === "") {
                      setNewPasswordError("These fields are required.");
                      check = false;
                    }
                    if (confirmPassword === "") {
                      setConfirmPasswordError("These fields are required.");
                      check = false;
                    }

                    if (newPassword !== confirmPassword) {
                      setConfirmPasswordError("Please confirm password");
                      check = false;
                    }

                    if (check) {
                      setLoadEvent(true);
                      await dispatch(
                        updateUserPassword({
                          old_password: oldPassword,
                          new_password: newPassword,
                        })
                      ).then(() => {});
                      setLoadEvent(false);
                    } else {
                      toast.error(
                        "Some fields are empty or invalid. please try again"
                      );
                    }
                  }}
                  className="text-white bg-danger text-sm px-5 py-3 rounded-full"
                >
                  {1 == 2 ? "Loading ..." : "Update Profile"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default SettingsScreen;
